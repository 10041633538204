import { render, staticRenderFns } from "./difficulty.vue?vue&type=template&id=27526ac0&scoped=true&"
import script from "./difficulty.vue?vue&type=script&lang=js&"
export * from "./difficulty.vue?vue&type=script&lang=js&"
import style0 from "./difficulty.vue?vue&type=style&index=0&id=27526ac0&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27526ac0",
  null
  
)

export default component.exports