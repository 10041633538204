<template>
  <div class="content">
    <a-button type="link" :disabled="disabled" @click="openModal"
      >关联：{{ value == 1 ? "系统知识点" : "自建知识点" }}</a-button
    >
    <a-modal v-model="visible" title="设定“考察内容”" @ok="handleOk">
      <div class="title">请选择一项</div>
      <a-radio-group v-model="value">
        <a-radio :style="radioStyle" :value="1"> 系统知识点 </a-radio>
        <a-radio :style="radioStyle" :value="2" disabled>
          自建知识点（暂不开放）
        </a-radio>
      </a-radio-group>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      radioStyle: {
        display: "block",
      },
      visible: false,
      value: 1,
    };
  },
  methods: {
    openModal() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
      this.$emit("change", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: 400;
  color: #0a1119;
  margin-bottom: 16px;
}
::v-deep .ant-radio-wrapper {
  margin-bottom: 16px;
}
</style>
