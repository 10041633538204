<template>
  <div class="ability">
    <a-button type="link" :disabled="disabled" @click="openModal"
      >选项：{{ selectedNum }}</a-button
    >
    <a-modal
      v-model="visible"
      :title="`设定“${title}”`"
      @cancel="handleCancel"
      @ok="handleOk"
    >
      <div class="title">
        最多可选择 {{ title == "能力层次" ? "6" : "7" }} 项
      </div>
      <div class="modal-box-content">
        <div class="partItem">
          <a-checkbox
            v-model="checkAll"
            :indeterminate="indeterminate"
            @change="onCheckAllChange"
          >
            常用
          </a-checkbox>
          <div class="content">
            <a-checkbox-group v-model="checkedList" @change="onChange">
              <a-checkbox
                v-for="(item, index) in plainOptions"
                :key="index"
                :value="item.value"
              >
                <span v-if="item.value != 7">{{ item.label }}</span>
                <div v-else class="underLine"></div>
              </a-checkbox>
            </a-checkbox-group>
          </div>
        </div>
        <div v-if="title === '能力层次'" class="partItem">
          <a-checkbox
            v-model="subjectCheckAll"
            :indeterminate="subjectIndeterminate"
            @change="subjectonCheckAllChange"
          >
            {{ subjectName }}
          </a-checkbox>
          <div class="content">
            <a-checkbox-group
              v-model="subjectCheckedList"
              :options="subjectPlainOptions"
              @change="subjectonChange"
            />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { getAbility, getAnalysis } from "@/core/api/exam/twoWay";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    paperId: {
      type: [String, Number, null],
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      visible: false,
      checkAll: false,
      indeterminate: false,
      checkedList: [],
      plainOptions: [],
      subjectCheckedList: [],
      subjectCheckAll: false,
      subjectIndeterminate: false,
      subjectPlainOptions: [],
      selectedNum: 0,
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      if (this.title === "能力层次") {
        this.getAbilityData();
      } else if (this.title === "失分原因分析") {
        this.getAnalysisData();
      }
    },
    handleCancel() {
      this.checkedList = [];
      this.subjectCheckedList = [];
      this.$emit("reset");
    },
    async getAbilityData() {
      try {
        this.loading = true;
        const res = await getAbility({
          examPaperId: this.paperId,
        });
        let normal = res.data.data.filter((item) => item.subjectId == -1);
        this.plainOptions = normal.map((item) => ({
          label: item.name,
          value: item.abilityType,
        }));
        let subject = res.data.data.filter((item) => item.subjectId != -1);
        this.subjectPlainOptions = subject.map((item) => ({
          label: item.name,
          value: item.abilityType,
        }));
        this.checkedList = [];
        this.checkAll = false;
        if (this.form.commonUse) {
          this.checkedList = this.form.commonUse.split(",").map(Number);
          this.onChange();
        }

        this.subjectCheckedList = [];
        if (this.form.subjectLevel) {
          this.subjectCheckedList = this.form.subjectLevel
            .split(",")
            .map(Number);

          this.subjectonChange();
        }
        this.selectedNum =
          this.checkedList.length + this.subjectCheckedList.length;
        this.subjectCheckAll = false;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async getAnalysisData() {
      try {
        this.loading = true;
        const res = await getAnalysis({ examPaperId: this.paperId });
        this.plainOptions = res.data.data.map((item) => ({
          label: item.name,
          value: item.analysisType,
        }));
        if (this.form.analysisOtherReason) {
          this.checkedList = this.form.analysisOtherReason
            .split(",")
            .map(Number);
          this.onChange();
          this.selectedNum = this.checkedList.length;
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    openModal() {
      this.init();
      this.visible = true;
    },
    handleOk() {
      let limit = this.title == "能力层次" ? 6 : 7;
      if (this.checkedList.length + this.subjectCheckedList.length > limit) {
        this.$message({
          showClose: true,
          message: `最多可选择${limit}项`,
          type: "warning",
        });
        return false;
      }
      if (this.checkedList.length + this.subjectCheckedList.length === 0) {
        this.$message({
          showClose: true,
          message: "请至少选择一项",
          type: "warning",
        });
        return false;
      }
      this.selectedNum =
        this.checkedList.length + this.subjectCheckedList.length;
      if (this.title == "能力层次") {
        this.$emit("change", {
          commonUse: this.checkedList.join(","),
          subjectLevel: this.subjectCheckedList.join(","),
        });
      } else {
        this.$emit("change", {
          analysisCommonUse: this.checkedList.join(","),
          subjectAnalysis: this.subjectCheckedList.join(","),
        });
      }
      this.visible = false;
    },
    onCheckAllChange() {
      this.checkedList = this.checkAll
        ? this.plainOptions.map((item) => item.value)
        : [];
      this.indeterminate = false;
    },
    onChange() {
      this.checkAll = this.checkedList.length === this.plainOptions.length;
      this.indeterminate =
        this.checkedList.length > 0 &&
        this.checkedList.length < this.plainOptions.length;
    },
    subjectonCheckAllChange() {
      this.subjectCheckedList = this.subjectCheckAll
        ? this.subjectPlainOptions.map((item) => item.value)
        : [];
      this.subjectIndeterminate = false;
    },
    subjectonChange() {
      this.subjectCheckAll =
        this.subjectCheckedList.length === this.subjectPlainOptions.length;
      this.subjectIndeterminate =
        this.subjectCheckedList.length > 0 &&
        this.subjectCheckedList.length < this.subjectPlainOptions.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 14px;
  font-weight: 400;
  color: #0a1119;
  margin-bottom: 16px;
}

.content {
  margin-bottom: 24px;
  margin-top: 8px;
  margin-left: 32px;
}

::v-deep .ant-checkbox-group-item {
  display: block;
  margin-bottom: 8px;
}

::v-deep .ant-btn-link {
  height: auto;
}

::v-deep .ant-checkbox-wrapper {
  display: block;
  margin-bottom: 8px;
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0 !important;
}
.partItem {
  margin-bottom: 24px;
}

.modal-box-content {
  height: 450px;
  overflow-y: auto;
}

.underLine {
  display: inline-block;
  width: 85px;
  border-bottom: 1px solid #d9d9d9;
}
</style>
