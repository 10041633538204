<template>
  <div class="changeStyle">
    <a-button @click="openModal">更换样式</a-button>
    <a-modal
      v-model="visible"
      :confirm-loading="confirmLoading"
      width="984px"
      title="更换样式"
      @ok="handleOk"
    >
      <div class="title">应用于：{{ $parent.subjectName }}</div>
      <div class="table-box">
        <div class="box left">
          <div
            v-for="(item, index) in styleOptions"
            :key="index"
            :class="['style-item', index === styleType ? 'style-active' : '']"
            @click="handleClick(index)"
          >
            <div class="radio"></div>
            {{ item }}
          </div>
        </div>
        <div class="box right">
          <div class="box-tag">示意图</div>
          <a-table
            :columns="columns"
            :data-source="tableData"
            :pagination="false"
            bordered
            :row-key="(record) => record.id"
          >
          </a-table>
        </div>
      </div>
      <div class="statType">
        <div class="sub-title">统计方式</div>
        <div class="radios">
          <a-radio-group v-model="statType" @change="radioChange">
            <a-radio :value="0" class="border"> 按原题号统计 </a-radio>
            <a-radio :value="1">
              {{ styleType === 0 ? "按新题号统计" : "按版块统计" }}
              <div class="checkbox">
                <a-checkbox-group v-model="mergeType" :disabled="statType == 0">
                  <a-checkbox value="0"> 合并分值 </a-checkbox>
                  <a-checkbox v-if="styleType === 0" value="1">
                    合并得分
                  </a-checkbox>
                  <a-checkbox v-if="styleType === 0" value="2">
                    合并得分率
                  </a-checkbox>
                </a-checkbox-group>
              </div>
            </a-radio>
          </a-radio-group>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { saveStyle } from "@/core/api/exam/twoWay";
export default {
  props: {
    styleSource: {
      type: Array,
      default: () => {},
    },
    paperId: {
      type: [Number, null],
      default: null,
    },
    role: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      styleOptions: ["标准样式", "版块样式"],
      styleType: 0,
      columns: [],
      tableData: [],
      statType: 1,
      confirmLoading: false,
      mergeType: [],

      styleObj: {},
    };
  },
  watch: {
    styleSource: {
      deep: true,
      handler(val) {
        this.styleObj = val.find((item) => item.applyType === this.role);
        this.styleType = Number(this.styleObj?.styleType) || 0;
        this.statType = this.styleObj?.questionStyleType;
        this.mergeType = this.styleObj?.newQuestionStatistics.split(",");
        this.handleClick(this.styleType);
        this.$emit("change", {
          update: false,
          styleType: this.styleType,
          statType: this.statType,
        });
      },
    },
  },
  mounted() {
    // this.handleClick(0);
  },
  methods: {
    radioChange() {
      if (this.styleType == 0) {
        if (this.statType == 0) {
          this.setNormal();
        } else if (this.statType == 1) {
          this.setNormaByNew();
        }
      } else {
        if (this.statType == 0) {
          this.setPlateStyle();
        } else if (this.statType == 1) {
          this.setPlateStyleByNew();
        }
      }
    },
    openModal() {
      this.visible = true;
    },
    handleClick(index) {
      this.styleType = index;
      if (index == 0) {
        if (this.statType == 0) {
          this.setNormal();
        } else if (this.statType == 1) {
          this.setNormaByNew();
        }
      } else {
        if (this.statType == 0) {
          this.setPlateStyle();
        } else if (this.statType == 1) {
          this.setPlateStyleByNew();
        }
      }
    },
    setNormal() {
      this.columns = [
        {
          title: "题型",
          align: "center",
          dataIndex: "questionType",
          width: 60,
        },
        {
          title: "原题号",
          align: "center",
          dataIndex: "originQuesitonNum",
          width: 75,
        },
        {
          title: "分值",
          align: "center",
          dataIndex: "score",
          width: 60,
        },
        {
          title: "年级均分",
          align: "center",
          dataIndex: "gradeScore",
          width: 75,
        },
        {
          title: "年级得分率",
          align: "center",
          dataIndex: "gradeScoreRate",
          width: 90,
        },
        {
          title: "能力层次",
          align: "center",
          dataIndex: "abilityLevels",
          children: [
            {
              title: "了解",
              align: "center",
              dataIndex: "understand",
              width: 45,
              customRender: (text, record) =>
                record.understand == 1 ? <a-icon type="check" /> : "",
            },
            {
              title: "掌握",
              align: "center",
              dataIndex: "master",
              width: 45,
              customRender: (text, record) =>
                record.understand == 1 ? <a-icon type="check" /> : "",
            },
          ],
        },
        {
          title: "考察内容",
          align: "center",
          dataIndex: "content",
          width: 75,
        },
      ];
      this.tableData = [
        {
          questionType: "选择",
          originQuesitonNum: 1,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 1,
          master: 0,
          content: "知识点1",
        },
        {
          questionType: "选择",
          originQuesitonNum: 2,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 1,
          master: 0,
          content: "知识点1",
        },
        {
          questionType: "分析",
          originQuesitonNum: 3,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 0,
          master: 1,
          content: "知识点1",
        },
        {
          questionType: "分析",
          originQuesitonNum: 4,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 0,
          master: 1,
          content: "知识点1",
        },
      ];
    },
    setNormaByNew() {
      this.tableData = [
        {
          questionType: "选择",
          newQuesitonNum: "1～3",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 1,
          master: 0,
          content: "知识点1",
        },
        {
          questionType: "选择",
          newQuesitonNum: "1～3",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 1,
          master: 0,
          content: "知识点1",
        },
        {
          questionType: "分析",
          newQuesitonNum: "1～3",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 0,
          master: 1,
          content: "知识点1",
        },
        {
          questionType: "分析",
          newQuesitonNum: 4,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          understand: 0,
          master: 1,
          content: "知识点1",
        },
      ];
      this.columns = [
        {
          title: "题型",
          align: "center",
          dataIndex: "questionType",
          width: 60,
        },
        {
          title: "新题号",
          align: "center",
          dataIndex: "newQuesitonNum",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let arr = this.tableData.filter(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let obj = {
              children: record.newQuesitonNum,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "分值",
          align: "center",
          dataIndex: "score",
          width: 60,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let arr = this.tableData.filter(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let obj = {
              children: record.score,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "年级均分",
          align: "center",
          dataIndex: "gradeScore",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let arr = this.tableData.filter(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let obj = {
              children: record.gradeScore,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "年级得分率",
          align: "center",
          dataIndex: "gradeScoreRate",
          width: 90,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let arr = this.tableData.filter(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let obj = {
              children: record.gradeScoreRate,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "能力层次",
          align: "center",
          dataIndex: "abilityLevels",
          children: [
            {
              title: "了解",
              align: "center",
              dataIndex: "understand",
              width: 45,
              customRender: (text, record) =>
                record.understand == 1 ? <a-icon type="check" /> : "",
            },
            {
              title: "掌握",
              align: "center",
              dataIndex: "master",
              width: 45,
              customRender: (text, record) =>
                record.understand == 1 ? <a-icon type="check" /> : "",
            },
          ],
        },
        {
          title: "考察内容",
          align: "center",
          dataIndex: "content",
          width: 75,
        },
      ];
    },
    setPlateStyle() {
      this.columns = [
        {
          title: "内容版块",
          align: "center",
          dataIndex: "plateContent",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.plateContent,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "考察内容",
          align: "center",
          dataIndex: "content",
          width: 75,
        },
        {
          title: "题型",
          align: "center",
          dataIndex: "questionType",
          width: 60,
        },
        {
          title: "原题号",
          align: "center",
          dataIndex: "originQuesitonNum",
          width: 75,
        },
        {
          title: "分值",
          align: "center",
          dataIndex: "score",
          width: 60,
        },
        {
          title: "版块总分",
          align: "center",
          dataIndex: "plateScore",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.plateScore,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "年级均分",
          align: "center",
          dataIndex: "gradeScore",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.gradeScore,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "预估难度",
          align: "center",
          dataIndex: "estimateDiffculty",
          width: 90,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.estimateDiffculty,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "年级得分率",
          align: "center",
          dataIndex: "gradeScoreRate",
          width: 85,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.gradeScoreRate,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "1班",
          align: "center",
          dataIndex: "classNum",
          width: 75,
        },
      ];
      this.tableData = [
        {
          questionType: "选择",
          originQuesitonNum: 1,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          estimateDiffculty: 0.9,
          plateScore: 30,
          content: "知识点1",
          plateContent: "版块A",
          classNum: 26,
        },
        {
          questionType: "选择",
          originQuesitonNum: 2,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          estimateDiffculty: 0.9,
          plateScore: 30,
          content: "知识点1",
          plateContent: "版块A",
          classNum: 26,
        },
        {
          questionType: "分析",
          originQuesitonNum: 3,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "80%",
          estimateDiffculty: 0.62,
          plateScore: 20,
          content: "知识点2",
          plateContent: "版块B",
          classNum: 15,
        },
        {
          questionType: "判断",
          originQuesitonNum: 4,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "70%",
          estimateDiffculty: 0.62,
          plateScore: 10,
          content: "知识点2",
          plateContent: "版块B",
          classNum: 15,
        },
        {
          questionType: "判断",
          originQuesitonNum: 5,
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "70%",
          estimateDiffculty: 0.62,
          plateScore: 10,
          content: "知识点3",
          plateContent: "版块C",
          classNum: 6,
        },
      ];
    },
    setPlateStyleByNew() {
      this.columns = [
        {
          title: "内容版块",
          align: "center",
          dataIndex: "plateContent",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.plateContent,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "考察内容",
          align: "center",
          dataIndex: "content",
          width: 75,
        },
        {
          title: "题型",
          align: "center",
          dataIndex: "questionType",
          width: 60,
        },
        {
          title: "新题号",
          align: "center",
          dataIndex: "newQuesitonNum",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let arr = this.tableData.filter(
              (item) => item.newQuesitonNum === record.newQuesitonNum
            );
            let obj = {
              children: record.newQuesitonNum,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },

        {
          title: "分值",
          align: "center",
          dataIndex: "score",
          width: 60,
        },
        {
          title: "版块总分",
          align: "center",
          dataIndex: "plateScore",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.plateScore,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "年级均分",
          align: "center",
          dataIndex: "gradeScore",
          width: 75,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.gradeScore,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "预估难度",
          align: "center",
          dataIndex: "estimateDiffculty",
          width: 90,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.estimateDiffculty,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "年级得分率",
          align: "center",
          dataIndex: "gradeScoreRate",
          width: 85,
          customRender: (text, record, index) => {
            let targetIndex = this.tableData.findIndex(
              (item) => item.plateContent === record.plateContent
            );
            let arr = this.tableData.filter(
              (item) => item.plateContent === record.plateContent
            );
            let obj = {
              children: record.gradeScoreRate,
              attrs: {},
            };
            if (index == targetIndex) {
              obj.attrs.rowSpan = arr.length;
            } else {
              obj.attrs.rowSpan = 0;
            }
            return obj;
          },
        },
        {
          title: "1班",
          align: "center",
          dataIndex: "classNum",
          width: 75,
        },
      ];
      this.tableData = [
        {
          questionType: "选择",
          newQuesitonNum: "1~3",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          estimateDiffculty: 0.9,
          plateScore: 30,
          content: "知识点1",
          plateContent: "版块A",
          classNum: 26,
        },
        {
          questionType: "选择",
          newQuesitonNum: "1~3",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "90%",
          estimateDiffculty: 0.9,
          plateScore: 30,
          content: "知识点1",
          plateContent: "版块A",
          classNum: 26,
        },
        {
          questionType: "分析",
          newQuesitonNum: "4~5",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "80%",
          estimateDiffculty: 0.62,
          plateScore: 20,
          content: "知识点2",
          plateContent: "版块B",
          classNum: 15,
        },
        {
          questionType: "分析",
          newQuesitonNum: "4~5",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "70%",
          estimateDiffculty: 0.62,
          plateScore: 10,
          content: "知识点3",
          plateContent: "版块B",
          classNum: 15,
        },
        {
          questionType: "判断",
          newQuesitonNum: "6",
          score: 10,
          gradeScore: 9,
          gradeScoreRate: "70%",
          estimateDiffculty: 0.62,
          plateScore: 10,
          content: "知识点3",
          plateContent: "版块C",
          classNum: 6,
        },
      ];
    },
    async handleOk() {
      this.visible = false;
      try {
        this.confirmLoading = true;
        await saveStyle({
          applyType: this.role,
          classNum: this.$parent.classNum,
          examPaperId: this.paperId,
          id: this.styleObj.id,
          newQuestionStatistics: this.mergeType.join(","),
          questionStyleType: this.statType,
          specificationId: this.styleObj.specificationId,
          styleType: this.styleType,
        });
        this.$message({
          message: "保存成功",
          type: "success",
          showClose: true,
        });
        this.$emit("change", {
          update: true,
          styleType: this.styleType,
          statType: this.statType,
        });
        this.confirmLoading = false;
      } catch {
        this.confirmLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin baseFlex($at: initial, $jc: initial) {
  display: flex;
  align-items: $at;
  justify-content: $jc;
}
.title {
  color: #0a1119;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 18px;
  text-align: left;
}
.table-box {
  @include baseFlex(flex-start, center);
  margin-bottom: 8px;
  .box + .box {
    margin-left: 8px;
  }
  .left {
    width: 150px;
    height: 248px;
    background: #ffffff;
    border: 1px solid #dee0e7;
    box-sizing: border-box;
    padding: 16px;
    padding-top: 35px;
    .style-item {
      border: 1px solid #dee0e7;
      height: 40px;
      background: #ffffff;
      border-radius: 4px;
      @include baseFlex(center, center);
      color: #0a1119;
      cursor: pointer;
      margin-bottom: 16px;
      user-select: none;
      .radio {
        width: 14px;
        height: 14px;
        border: 1px solid #d5d6db;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .style-active {
      background: #f3f8ff;
      border: 1px solid #dee0e7;
      color: #2474ed;
      transition: all 0.3s;
      .radio {
        background: #2474ed;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          background-color: #fff;
          border-radius: 50%;
          width: 6px;
          height: 6px;
        }
      }
    }
  }
  .right {
    height: 248px;
    background: #ffffff;
    border: 1px solid #dee0e7;
    box-sizing: border-box;
    padding: 16px;
    flex: 1;
    position: relative;
    padding-top: 35px;
    .box-tag {
      position: absolute;
      left: 0;
      top: 0;
      box-sizing: border-box;
      padding: 4px 10px;
      background-color: #f3f8ff;
      color: #2474ed;
      text-align: center;
      z-index: 4;
    }
  }
}
// .antd-table td {
//   white-space: nowrap;
// }
// ::v-deep .ant-table-fixed-header .ant-table-scroll .ant-table-header {
//   overflow: hidden !important;
//   margin-bottom: 0 !important;
// }
::v-deep .ant-table-body {
  overflow-x: hidden !important;
}
::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 4px !important;
}
::v-deep .ant-table-row-cell-break-word {
  padding: 4px !important;
}
.statType {
  background: #ffffff;
  border: 1px solid #dee0e7;
  box-sizing: border-box;
  padding: 10px;
}
.sub-title {
  color: #0a1119;
  font-size: 14px;
  margin-bottom: 8px;
}
::v-deep .ant-radio-wrapper {
  min-width: 150px;
  margin-right: 40px;
  min-height: 40px;
}
.border {
  border-right: 1px solid #dee0e7;
}
.checkbox {
  margin-top: 8px;
}
::v-deep .ant-radio-group {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 767px) {
  ::v-deep .ant-modal {
    max-width: 984px !important;
  }
}
</style>
