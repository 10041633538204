<template>
  <div class="batchProcess">
    <a-button
      :disabled="selectedRows.length === 0"
      type="primary"
      @click="openModal"
      >批量设定题型</a-button
    >

    <a-modal v-model="visible" title="批量设定题型" @ok="handleOk">
      <div class="title">应用于：{{ $parent.subjectName }}</div>
      <div class="sub-title">已选题号</div>
      <div class="question-list">
        <div
          v-for="(item, index) in selectedRows"
          :key="index"
          class="question-item"
          @click="del(item, index)"
        >
          {{ item.questionNum }} <a-icon type="delete" />
        </div>
      </div>
      <div class="sub-title">设定为</div>
      <div class="radios">
        <a-radio-group v-model="questionType">
          <a-radio
            v-for="(item, index) in questionTypeList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-radio>
        </a-radio-group>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "BatchProcess",
  props: {
    selectedRows: {
      type: Array,
      default: () => [],
    },
    questionTypeList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      questionType: null,
    };
  },
  created() {},
  methods: {
    openModal() {
      this.visible = true;
    },
    handleOk() {
      if (this.questionType === null) {
        this.$message({
          showClose: true,
          message: "请选择题型",
          type: "warning",
        });
        return false;
      } else if (!this.selectedRows.length) {
        this.$message({
          showClose: true,
          message: "请选择至少一道题",
          type: "warning",
        });
        return false;
      }
      this.visible = false;
      this.$emit("change", {
        questionNums: this.selectedRows.map((item) => item.questionNum),
        questionType: this.questionType,
      });
    },
    del(item, index) {
      this.$emit("del", { item: item, index: index });
    },
  },
};
</script>
<style lang="scss" scoped>
.batchProcess {
  margin-right: 8px;
}
.title {
  margin-bottom: 16px;
  color: #0a1119;
  font-size: 14px;
  font-weight: 600;
}
.sub-title {
  margin-bottom: 16px;
  color: #0a1119;
  font-size: 14px;
  font-weight: 500;
}
.question-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
  .question-item {
    margin-right: 8px;
    margin-bottom: 8px;
    min-width: 32px;
    height: 24px;
    padding: 0 4px;
    text-align: center;
    color: #2474ed;
    background: #f2f6ff;
    border-radius: 4px;
    cursor: pointer;
    line-height: 24px;
    font-size: 12px;
    user-select: none;
  }
}
::v-deep .ant-radio-wrapper {
  margin-bottom: 4px !important;
}
</style>
