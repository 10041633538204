var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"plateDivision"},[_c('a-button',{on:{"click":_vm.openModal}},[_vm._v("划分版块")]),_c('a-modal',{attrs:{"width":"fit-content","confirm-loading":_vm.confirmLoading,"title":"划分版块"},on:{"ok":_vm.handleOk},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"subject"},[_vm._v("应用于："+_vm._s(_vm.$parent.subjectName))]),_c('a-button',{staticStyle:{"margin-bottom":"16px"},attrs:{"type":"primary"},on:{"click":_vm.add}},[_vm._v("添加")]),_c('div',{staticClass:"operate-box"},[_c('div',{staticClass:"box table"},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[_c('a-table',{ref:"table",attrs:{"data-source":_vm.form.tableData,"columns":_vm.columns,"bordered":"","scroll":{ y: 295 },"pagination":false,"custom-row":_vm.customRow},scopedSlots:_vm._u([{key:"sort",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].sort`,"rules":{
                    validator: (rule, value, callback) =>
                      _vm.validSort(rule, value, callback, record, index),
                    trigger: ['blur', 'change'],
                  }}},[_c('a-input',{staticClass:"nameInput",attrs:{"placeholder":"排序"},model:{value:(record.sort),callback:function ($$v) {_vm.$set(record, "sort", _vm._n($$v))},expression:"record.sort"}})],1)]}},{key:"name",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].name`,"rules":{
                    validator: (rule, value, callback) =>
                      _vm.validPlateName(rule, value, callback, record, index),
                    trigger: ['blur', 'change'],
                  }}},[_c('a-input',{staticClass:"nameInput",attrs:{"placeholder":"未命名模块"},model:{value:(record.name),callback:function ($$v) {_vm.$set(record, "name", $$v)},expression:"record.name"}})],1)]}},{key:"questionNum",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].questionNum`,"rules":{
                    validator: (rule, value, callback) =>
                      _vm.validateQuestionNum(
                        rule,
                        value,
                        callback,
                        record,
                        index
                      ),
                    trigger: 'change',
                  }}},[_c('div',{ref:"questionNumBox",staticClass:"questionNumBox"},[(record.questionNum && !record.questionNum.length)?_c('span',[_vm._v("从题号列表中选择以添加")]):_vm._l((record.questionNum),function(item,ind){return _c('div',{key:ind,staticClass:"quesitonItem",on:{"click":function($event){return _vm.delItem(item, index, ind)}}},[_vm._v(" "+_vm._s(item.questionNum)+" "),_c('a-icon',{attrs:{"type":"delete"}})],1)})],2)])]}},{key:"actions",fn:function(text, record, index){return [_c('a-button',{staticStyle:{"color":"red"},attrs:{"type":"link"},on:{"click":function($event){return _vm.del(record, index)}}},[_vm._v("删除")])]}}])})],1)],1),_c('div',{staticClass:"box questionList"},[_c('div',{staticClass:"header"},[_vm._v("原始题号列表")]),_c('div',{staticClass:"conetent"},_vm._l((_vm.questionList),function(item,index){return _c('div',{key:index,staticClass:"questionNumItem",on:{"click":function($event){return _vm.handleSelect(item, index)}}},[_vm._v(" "+_vm._s(item.questionNum)+" ")])}),0)])])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }