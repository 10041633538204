export const ablityTypes = [
  {
    label: "识记",
    value: 0,
  },
  {
    label: "了解",
    value: 1,
  },
  {
    label: "理解",
    value: 2,
  },
  {
    label: "掌握",
    value: 3,
  },
  {
    label: "理解/掌握",
    value: 4,
  },
  {
    label: "理解/推理",
    value: 5,
  },
  {
    label: "应用",
    value: 6,
  },
  {
    label: "实验",
    value: 7,
  },
  {
    label: "实验/探究",
    value: 8,
  },
  {
    label: "应用问题",
    value: 9,
  },
  {
    label: "分析综合",
    value: 10,
  },
  {
    label: "表达应用",
    value: 11,
  },
  {
    label: "评价鉴赏",
    value: 12,
  },
  {
    label: "探究",
    value: 13,
  },
  {
    label: "获取和解读历史信息",
    value: 14,
  },
  {
    label: "分析历史问题",
    value: 15,
  },
  {
    label: "历史探究",
    value: 16,
  },
];
export const analysisTypes = [
  {
    label: "粗心",
    value: 0,
  },
  {
    label: "审题不到位",
    value: 1,
  },
  {
    label: "时间安排不当",
    value: 2,
  },
  {
    label: "留空白题",
    value: 3,
  },
  {
    label: "其他",
    value: 4,
  },
  {
    label: "书写潦草",
    value: 5,
  },
  {
    label: "错别字",
    value: 6,
  },
  {
    label: "表达含糊",
    value: 7,
  },
];
export const difficultType = [
  {
    label: "容易",
    value: 0,
  },
  {
    label: "较易",
    value: 1,
  },
  {
    label: "一般",
    value: 2,
  },
  {
    label: "较难",
    value: 3,
  },
  {
    label: "困难",
    value: 4,
  },
];
