<template>
  <div v-loading="loading" class="container">
    <detailsHeader />
    <div class="operate">
      <div class="subjectList">
        <div
          v-for="(item, index) in subjectList"
          :key="index"
          :class="['subject-item', item.id == subjectId ? 'active' : '']"
          @click="handleClick(item)"
        >
          {{ item.name }}
        </div>
        <a-dropdown v-if="menuList.length">
          <div
            :class="[
              'subject-item',
              'extend',
              menuListIds.includes(subjectId) ? 'active' : '',
            ]"
          >
            {{ text }}
            <a-icon type="caret-down" />
          </div>
          <a-menu slot="overlay" @click="handleClickMenu">
            <a-menu-item v-for="item in menuList" :key="item.id">
              {{ item.name }}
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </div>
      <div>
        <a-button style="margin-right: 10px" @click="goBack">返回 </a-button>
        <!-- <a-button type="danger" ghost>删除</a-button> -->
      </div>
    </div>
    <template v-if="ifPreview === 0 && paperId && dataSource">
      <no-data v-if="[1, 2].includes(maxRole)" message="暂无数据"></no-data>
      <create
        v-else
        :key="paperId"
        ref="create"
        :subject-name="subjectName"
        :subject-id="subjectId"
        :paper-id="paperId"
        :data-source="dataSource"
        @change="createChange"
        @preview="previewChange"
      />
    </template>
    <template v-else-if="ifPreview === 1 && paperId">
      <detail
        :key="paperId"
        :subject-name="subjectName"
        :subject-id="subjectId"
        :role-obj="roleObj"
        :paper-id="paperId"
        :exam-information="examInformation"
        :paper-list="paperList"
        :subject-list="subjectList"
        @change="detailChange"
        @preview="previewChange"
      />
    </template>
    <template v-else-if="ifPreview === null">
      <no-data
        title="暂无细目表"
        message="尚未设置答题卡模板，系统无法生成细目表"
      ></no-data>
      <div style="text-align: center">
        <a-button type="link" @click="goSetModel">前去设置</a-button>
      </div>
    </template>
  </div>
</template>
<script>
import { getObj } from "@/core/api/exam/exam";
import {
  specificationList,
  respecification,
  getRole,
} from "@/core/api/exam/twoWay";
import detailsHeader from "@/components/detailsHeader.vue";
import create from "./create.vue";
import detail from "./detail.vue";

export default {
  name: "TwoWayCheckList",
  components: {
    detailsHeader,
    create,
    detail,
  },

  data() {
    return {
      type: 0, // 0--备课组 1--任课教师 2--学生
      questionTypeList: [],
      examInformation: {},
      subjectList: [],
      menuList: [],
      menuListIds: [],
      subjectId: null,
      text: "",
      people1: [],
      people2: [],
      people3: [],
      people4: [],
      selectedRowKeys: [],
      form: {
        tableData: [],
      },
      selectedRows: [],

      loading: false,

      paperId: null,
      subjectName: "",
      analysisData: [],
      dataSource: null,
      paperList: [],
      roleObj: {},
      ifPreview: null, // 是否生成细目表 0 否 1 是
      maxRole: null,
    };
  },
  mounted() {
    this.getExamObj();
    // setTimeout(() => {
    //   this.form.tableData[0].content = '"0-0-0", "0-0-1"';
    //   this.form = JSON.parse(JSON.stringify(this.form));
    // }, 1000);
  },
  methods: {
    questionTypeChange() {},
    detailChange(type) {
      this.type = type;
    },
    createChange() {
      this.getData();
    },
    previewChange() {
      this.getRespecification();
    },
    goBack() {
      this.$router.go(-1);
    },
    async getRespecification() {
      const res = await respecification({ examPaperId: this.paperId });
      this.ifPreview = res.data.data ? res.data.data.ifPreview : null;
      if (this.ifPreview === 0) {
        this.dataSource = null;
        this.getData();
      }
    },

    async getData() {
      try {
        this.loading = true;
        const res = await specificationList({
          examPaperId: this.paperId,
        });

        this.dataSource = { ...res.data.data };
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    handleClickMenu({ key }) {
      this.ifPreview = null;
      this.subjectId = key;
      this.text = this.menuList.find((item) => item.id == this.subjectId).name;
      this.subjectName = this.text;
      this.paperId = this.menuList.find(
        (item) => item.id == this.subjectId
      ).paperId;
      this.getRespecification();
    },
    async getExamObj() {
      const res = await getObj(this.$route.query.examId);
      this.examInformation = res.data.data;
      this.paperList = this.examInformation.paperList.map((item) => ({
        name: item.subjectName,
        paperId: item.paperId,
        id: item.subjectId,
      }));

      this.getRoleData();
    },
    async getRoleData() {
      // 1    管理员
      // 2    客服账号
      // 3    助教用户
      // 4    学校学生
      // 5    学校老师
      const res = await getRole({ examId: this.examInformation.examId });
      this.roleObj = res.data.data;
      let teacherSubjects = res.data.data.subjectIds.split(",").map(Number);
      let paperList = [
        ...this.paperList.filter((item) => teacherSubjects.includes(item.id)),
      ];
      this.maxRole = res.data.data.teacherRole
        ? Math.max(...res.data.data.teacherRole.split(",").map(Number))
        : null;
      if (this.paperList.length > 9) {
        this.subjectList = paperList.slice(0, 9);
        this.menuList = this.paperList.slice(9);
        this.menuListIds = this.menuList.map((item) => item.id);
        this.subjectId = this.subjectList[0].id;
        this.subjectName = this.subjectList[0].name;
        this.paperId = this.subjectList[0].paperId;
      } else {
        this.subjectList = paperList;
        this.subjectId = this.subjectList[0].id;
        this.subjectName = this.subjectList[0].name;
        this.paperId = this.subjectList[0].paperId;
        this.menuList = [];
      }
      this.getRespecification();
    },
    goSetModel() {
      this.$router.push({
        path: "/exam/ExamTemplate",
        query: {
          examId: this.examInformation.examId,
          subjectId: this.subjectId,
        },
      });
    },
    handleClick(item) {
      this.ifPreview = null;
      this.subjectId = item.id;
      this.subjectName = item.name;
      this.paperId = item.paperId;
      this.text = "";
      this.getRespecification();
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin baseFlex() {
  display: flex;
  align-items: center;
}

.container {
  box-sizing: border-box;
  padding-bottom: 24px;
  background-color: #fff;
  margin-top: 24px;

  .operate {
    @include baseFlex;
    justify-content: space-between;
    padding: 0 24px;

    .subjectList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .subject-item {
        box-sizing: border-box;
        padding: 0 18px;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        margin-right: 8px;
        cursor: pointer;
        height: 32px;
        line-height: 32px;

        &:hover {
          color: #2474ed;
          border-color: #2474ed;
        }
      }

      .extend {
        padding: 0 10px;
      }

      .active {
        color: #2474ed;
        border-color: #2474ed;
      }
    }

    .peoples {
      justify-content: flex-start;
      @include baseFlex;
      flex-wrap: wrap;

      .people {
        justify-content: flex-start;
        @include baseFlex;
        margin-right: 24px;
      }
    }

    .operate-btn {
      @include baseFlex;
    }
  }

  .table {
    box-sizing: border-box;
    padding: 0 16px;
  }

  .tips {
    background: #f9fafc;
    border-radius: 4px;
    padding: 16px 18px;
    margin: 24px 18px;
  }
}

.btns {
  text-align: center;
  margin-top: 24px;

  .ant-btn + .ant-btn {
    margin-left: 8px;
  }
}

::v-deep .ant-form-item {
  margin-bottom: 0;
}

::v-deep .ant-form-explain {
  display: none !important;
}

.isError {
  ::v-deep .ant-radio-wrapper {
    color: red !important;
  }
}

.knowledgePointError {
  position: relative;
  transition: all 0.3 linear;

  &::after {
    position: absolute;
    left: 0;
    content: "请添加知识点";
    top: 105%;
    white-space: nowrap;
    color: red;
    line-height: 1;
    font-size: 12px;
  }
}

::v-deep .ant-form-item-control {
  text-align: left;
}

::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px !important;
}

::v-deep .ant-table-row-cell-break-word {
  padding: 6px 10px !important;
}
</style>
