<template>
  <div class="choosen">
    <a-button type="link" @click="openModal">{{
      teachers.length ? getTeacherName() : "选择"
    }}</a-button>
    <a-modal
      v-model="visible"
      :title="title"
      :confirm-loading="loading"
      width="45%"
      @ok="handleOk"
    >
      <div class="filters">
        <a-row>
          <a-col :span="8">
            <div class="filters-item">
              <div class="label">学段：</div>
              <a-select
                v-model="search.level"
                :disabled="disabledFilter"
                style="width: 100%"
                allow-clear
                placeholder="全部学段"
                @change="handleChange"
              >
                <a-select-option
                  v-for="(item, index) in levelOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="filters-item">
              <div class="label">入学年份：</div>
              <a-select
                v-model="search.year"
                :disabled="disabledFilter"
                style="width: 100%"
                placeholder="全部年份"
                allow-clear
                @change="handleChange"
              >
                <a-select-option
                  v-for="(item, index) in yearOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="filters-item">
              <div class="label">科目：</div>
              <a-select
                v-model="search.subjectId"
                :disabled="disabledFilter"
                placeholder="全部科目"
                style="width: 100%"
                allow-clear
                @change="handleChange"
              >
                <a-select-option
                  v-for="(item, index) in subjectOptions"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <div class="filters-item">
              <div class="label">权限：</div>
              <a-select
                v-model="search.role"
                :disabled="disabledFilter"
                placeholder="全部权限"
                style="width: 100%"
                allow-clear
                @change="handleChange"
              >
                <a-select-option
                  v-for="(item, index) in teacherRole"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
          <a-col :span="16">
            <a-input
              v-model="search.keyword"
              placeholder="请输入教师名称"
              style="width: 100%"
              @input="handleChange"
            ></a-input>
          </a-col>
        </a-row>
      </div>
      <div class="filter-box">
        <div class="box">
          <div class="box-header">
            <!-- <a-button
              type="link"
              :disabled="choosenTeacher.length >= 3"
              @click="checkAll"
              >全选</a-button
            > -->
            {{ checkTeacherList.length }}/3
          </div>
          <div class="teacherList">
            <el-checkbox-group
              v-model="checkTeacherList"
              :disabled="choosenTeacher.length >= 3"
              @change="setTeacherList"
            >
              <virtualList :list-data="teacherList" :container-height="'338px'">
                <template slot-scope="{ item }">
                  <el-checkbox :label="item.id">
                    {{ item.name }}
                  </el-checkbox>
                </template>
              </virtualList>
            </el-checkbox-group>
          </div>
        </div>
        <div class="box">
          <div class="box-header-choosen">
            <a-button type="link" @click="clearAll">清空</a-button>
          </div>
          <div class="teacherList">
            <virtualList
              :list-data="choosenTeacher"
              :container-height="'338px'"
            >
              <template slot-scope="{ item }">
                <div class="teacher">
                  <div>{{ item.name }}</div>
                  <div class="clear">
                    <a-button type="link" @click="closeItem(item)"
                      ><a-icon type="close"
                    /></a-button>
                  </div>
                </div>
              </template>
            </virtualList>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  specificationTeacher,
  specificationSaveTeacher,
} from "@/core/api/exam/twoWay";
import { levelOptions, teacherRole } from "@/core/util/constdata";
import { getSubjectList } from "@/core/util/util";
import virtualList from "@/components/virtualList.vue";
export default {
  components: {
    virtualList,
  },
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    examInformation: {
      type: Object,
      default: () => {},
    },
    disabledFilter: {
      type: Boolean,
      default: false,
      // 是否禁止过滤条件
    },
    paperId: {
      type: [Number, String, null],
      default: null,
    },
    title: {
      type: String,
      default: "提示",
    },
    type: {
      type: [Number, String],
      default: 1,
      require: true,
    },
    value: {
      type: [Array, String, null],
      default: null,
    },
  },
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearOptions = arr
      .map((item, index) => {
        return { label: index + 2015, value: index + 2015 };
      })
      .reverse();
    return {
      subjectOptions: getSubjectList(),
      visible: false,
      search: {},
      queryData: { ...this.$route.query },
      teacherList: [],
      loading: false,
      levelOptions: levelOptions(),
      yearOptions: yearOptions,
      teacherRole: teacherRole,
      checkTeacherList: [],
      choosenTeacher: [],
      teacherListMap: null,
      teachers: [],
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (this.value) {
          this.checkTeacherList = this.value.map((item) => item.teacherId);
          this.teachers = this.value.map((item) => ({
            ...item,
            id: item.teacherId,
            name: item.teacherName,
          }));
        }
      },
    },
  },
  methods: {
    clearAll() {
      this.choosenTeacher = [];
      this.checkTeacherList = [];
    },
    checkAll() {
      if (!this.hasSearch()) {
        // 如果没有筛选条件 接口返回全部老师 可以直接迁移
        this.checkTeacherList = this.teacherList.map((item) => item.id);
        this.choosenTeacher = [...this.teacherList];
      } else {
        // 如果有筛选条件  全选左侧数据不应该影响右侧已选数据
        // 将已选择数据转换为map对象
        let mapObj = this.buildMap(this.choosenTeacher);
        // 遍历当前老师数组 如果map 对象中不存在以id 为key 的对象 那么插入当前对象
        // 反之该数据已经被选中则不做任何操作
        const resultMap = this.teacherList.reduce((acc, item) => {
          if (!acc.has(item.id)) {
            acc.set(item.id, [item]);
          }
          return acc;
        }, mapObj);
        this.choosenTeacher = Array.from(resultMap.values()).flat(Infinity);
        this.checkTeacherList = this.choosenTeacher.map((item) => item.id);
      }
    },
    closeItem(item) {
      this.checkTeacherList = this.checkTeacherList.filter(
        (it) => it !== item.id
      );
      this.choosenTeacher = this.choosenTeacher.filter(
        (it) => it.id !== item.id
      );
    },
    openModal() {
      this.visible = true;
      this.search = {
        examId: this.queryData.examId,
        examPaperId: this.paperId,
        subjectId: this.$parent.subjectId,
      };
      this.getTeacherList();
    },
    setTeacherList(e) {
      this.choosenTeacher = [];
      e.forEach((item) => {
        this.choosenTeacher.push(...this.teacherListMap.get(item));
      });
    },
    hasSearch() {
      return (
        this.search.level ||
        this.search.keyword ||
        this.search.role ||
        this.search.year ||
        this.search.subjectId
      );
    },
    handleChange() {
      this.getTeacherList();
    },
    async getTeacherList() {
      this.loading = true;
      if (this.disabledFilter) {
        this.search.year = this.examInformation.year;
        this.search.level = this.examInformation.level.toString();
        this.search.role = 1;
      }
      try {
        const res = await specificationTeacher(this.search);
        this.teacherList = [...res.data.data];
        if (this.teacherListMap === null) {
          this.teacherListMap = this.buildMap(this.teacherList);
        }

        if (this.value.length) {
          this.setTeacherList(this.checkTeacherList);
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    buildMap(arr) {
      // 将数组转换为map对象
      const mapObj = arr.reduce((acc, item) => {
        if (!acc.has(item.id)) {
          acc.set(item.id, []);
        }
        acc.get(item.id).push(item);
        return acc;
      }, new Map());
      return mapObj;
    },
    async handleOk() {
      this.teachers = [...this.choosenTeacher];
      try {
        this.loading = true;
        await specificationSaveTeacher({
          specificationId: this.$parent.specificationId,
          examPaperId: this.paperId,
          type: this.type,
          teacherList: this.teachers,
        });
        this.loading = false;
        this.visible = false;
        this.$emit("change");
      } catch {
        this.loading = false;
      }
    },
    getTeacherName() {
      let teacherName = this.teachers.map((item) => item.name);
      return teacherName.join("，");
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin baseFlex($jc) {
  display: flex;
  align-items: center;

  justify-content: $jc;
}

.filters {
  &-item {
    margin-right: 10px;
    margin-bottom: 24px;
    @include baseFlex(flex-start);

    .label {
      width: fit-content;
      white-space: nowrap;
    }
  }
}

.filter-box {
  @include baseFlex(space-between);

  .box {
    flex: 1;
    border: 1px solid #dee1e7;

    &-header {
      padding: 9px 24px;
      background: #f5f7fa;
      height: 34px;
      @include baseFlex(flex-end);

      ::v-deep .ant-btn-link {
        padding: 0;
        line-height: 1;
        height: fit-content !important;
      }

      .ant-btn-link + .ant-btn-link {
        margin-left: 8px;
      }
    }

    &-header-choosen {
      padding: 9px 24px;
      background: #f5f7fa;
      @include baseFlex(flex-end);

      ::v-deep .ant-btn-link {
        padding: 0;
        line-height: 1;
        height: fit-content !important;
      }

      .ant-btn-link + .ant-btn-link {
        margin-left: 8px;
      }
    }

    .teacherList {
      ::v-deep .el-checkbox {
        display: block;
        margin-right: 0;
      }

      .teacher {
        width: 100%;
        @include baseFlex(space-between);
        height: 21px;
        line-height: 21px;
        margin-bottom: 0.5rem;
      }
    }
  }

  .box + .box {
    margin-left: 24px;
  }
}

::v-deep .list {
  padding: 8px 24px;
}

::v-deep .listItem {
  text-align: left;
}
</style>
