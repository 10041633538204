var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}]},[_c('div',{staticClass:"operate tips"},[_c('div',{staticClass:"peoples"},[_c('div',{staticClass:"people"},[_vm._v(" 命题人： "),_c('choosen',{attrs:{"paper-id":_vm.paperId,"type":0,"title":"请选择命题人"},model:{value:(_vm.people1),callback:function ($$v) {_vm.people1=$$v},expression:"people1"}})],1),_c('div',{staticClass:"people"},[_vm._v(" 审题人： "),_c('choosen',{attrs:{"paper-id":_vm.paperId,"type":1,"title":"请选择审题人"},model:{value:(_vm.people2),callback:function ($$v) {_vm.people2=$$v},expression:"people2"}})],1),_c('div',{staticClass:"people"},[_vm._v(" 备课组长： "),_c('choosen',{attrs:{"type":2,"paper-id":_vm.paperId,"title":"请选择备课组长"},model:{value:(_vm.people3),callback:function ($$v) {_vm.people3=$$v},expression:"people3"}})],1)]),_c('div',{staticClass:"operate-btn"},[_c('batchProcess',{attrs:{"selected-rows":_vm.selectedRows,"question-type-list":_vm.questionTypeList},on:{"del":_vm.batchDel,"change":_vm.batchProcessChange}}),_c('plateDivision',{attrs:{"exam-paper-id":_vm.paperId,"data-source":_vm.form.tableData,"specification-id":_vm.specificationId},on:{"change":_vm.editChange}}),_c('editQuestionNum',{attrs:{"exam-paper-id":_vm.paperId,"data-source":_vm.form.tableData,"specification-id":_vm.specificationId},on:{"change":_vm.editChange}}),_c('configureTable',{attrs:{"subject-name":_vm.subjectName,"paper-id":_vm.paperId},on:{"change":_vm.configureTableChange}})],1)]),_c('div',{staticClass:"table"},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form}},[_c('a-table',{attrs:{"data-source":_vm.form.tableData,"columns":_vm.columns,"row-selection":{
          selectedRowKeys: _vm.selectedRowKeys,
          onChange: _vm.onSelectChange,
        },"scroll":{ x: 'max-content' },"bordered":"","pagination":false},scopedSlots:_vm._u([{key:"questionType",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].questionType`,"rules":{ required: true, message: '', trigger: 'change' }}},[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"请选择题型"},on:{"change":(e) => _vm.tableChange(e, record, index)},model:{value:(record.questionType),callback:function ($$v) {_vm.$set(record, "questionType", $$v)},expression:"record.questionType"}},_vm._l((_vm.questionTypeList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_c('a-tooltip',{attrs:{"placement":"top"}},[_c('template',{slot:"title"},[_c('span',[_vm._v(" "+_vm._s(item.name))])]),_c('span',[_vm._v(" "+_vm._s(item.name))])],2)],1)}),1)],1)]}},{key:"newQuestionNum",fn:function(text, record){return [(record.newQuestionNum)?_c('span',[_vm._v(_vm._s(record.newQuestionNum))]):_c('span',{staticStyle:{"color":"#dadbe0"}},[_vm._v(_vm._s(record.questionNum))])]}},{key:"preCoefficient",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].preCoefficient`,"rules":{
              validator: (rule, value, callback) =>
                _vm.validpreCoefficient(rule, value, callback, record, index),
              trigger: 'change',
            }}},[_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"min":0,"placeholder":"范围：0～1","max":1,"step":0.1},on:{"blur":(e) => _vm.tableChange(e, record, index)},model:{value:(record.preCoefficient),callback:function ($$v) {_vm.$set(record, "preCoefficient", $$v)},expression:"record.preCoefficient"}})],1)]}},{key:"abilityLevel",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].abilityLevel`,"rules":{
              validator: (rule, value, callback) =>
                _vm.validAbilityLevels(rule, value, callback, record, index),
              trigger: 'change',
            }}},[_c('a-radio-group',{staticClass:"ability",attrs:{"options":_vm.plainOptions},on:{"change":(e) => _vm.tableChange(e, record, index)},model:{value:(record.abilityLevel),callback:function ($$v) {_vm.$set(record, "abilityLevel", $$v)},expression:"record.abilityLevel"}})],1)]}},{key:"inspectionContent",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].inspectionContent`}},[(_vm.treeData.length)?_c('knowledgePoint',{staticClass:"knowledgePoint",attrs:{"tree-data":_vm.treeData,"tree-data-list":_vm.treeDataList,"exam-paper-id":_vm.paperId,"value":{
                ids: record.inspectionContent,
                names: record.inspectionContentName,
              }},on:{"change":(val) => _vm.knowledgePointChange(val, record, index)}}):_vm._e()],1)]}},{key:"reasonForLossOfPoints",fn:function(text, record, index){return [_c('a-form-model-item',{attrs:{"prop":`tableData[${index}].reasonForLossOfPoints`}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"当前不可选，请在生成细目表后操作","placement":"top"}},[_c('reasonVue',{staticClass:"reasonVue",attrs:{"source-data":_vm.analysisData,"disabled":"","has-other":_vm.hasOther}})],1)],1)]}}])})],1)],1),_c('div',{staticClass:"btns"},[_c('a-button',{attrs:{"type":"primary","loading":_vm.btnLoading},on:{"click":_vm.create}},[_vm._v("生成细目表")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }