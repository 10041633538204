<template>
  <div class="difficulty">
    <a-button type="link" :disabled="disabled" @click="openModal"
      >采用：{{ value == 1 ? "系数" : "等级" }}</a-button
    >
    <a-modal v-model="visible" :title="`设定“${title}”`" @ok="handleOk">
      <div class="title">请选择一项</div>
      <a-radio-group v-model="value">
        <a-radio :style="radioStyle" :value="1">
          系数
          <div class="content">0~1</div>
        </a-radio>
        <a-radio disabled :style="radioStyle" :value="2"
          >等级
          <div class="content">
            <a-checkbox-group
              v-model="checkedList"
              disabled
              :options="plainOptions"
            />
          </div>
        </a-radio>
      </a-radio-group>
    </a-modal>
  </div>
</template>

<script>
import { difficultType } from "../enmu";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    fileds: {
      type: Array,
      default: () => [],
    },
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },

  data() {
    return {
      visible: false,
      value: 1,
      radioStyle: {
        display: "block",
      },
      checkedList: [0, 2, 4],
      plainOptions: [...difficultType],
    };
  },
  mounted() {
    this.$nextTick(() => {
      let filedsStr = this.fileds.join(",");
      if (filedsStr === "preCoefficient,preLevel") {
        if (this.form.preCoefficient) {
          this.value = 1;
        } else if (this.form.preLevel) {
          this.value = 2;
          let arr = this.form.preLevel.split(",");
          this.checkedList = arr.map(Number);
        }
      } else if (filedsStr === "relCoefficient,relLevel") {
        if (this.form.relCoefficient) {
          this.value = 1;
        } else if (this.form.relLevel) {
          this.value = 2;
          let arr = this.form.relLevel.split(",");
          this.checkedList = arr.map(Number);
        }
      }
    });
  },
  methods: {
    openModal() {
      this.visible = true;
    },
    handleOk() {
      if (!this.value) {
        this.$message({
          showClose: true,
          message: "请设定预估难度",
          type: "warning",
        });
        return false;
      }
      let data = {
        [`${this.fileds[0]}`]: null,
        [`${this.fileds[1]}`]: null,
      };
      if (this.value == 1) {
        data[`${this.fileds[0]}`] = "0~1";
      } else if (this.value == 2) {
        if (!this.checkedList.length) {
          this.$message({
            showClose: true,
            message: "请选择至少一个等级",
            type: "warning",
          });
          return false;
        }
        data[`${this.fileds[1]}`] = this.checkedList.join(",");
      }
      this.$emit("change", data);
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.difficulty {
}
.title {
  font-size: 14px;
  font-weight: 400;
  color: #0a1119;
  margin-bottom: 16px;
}
.content {
  margin-bottom: 24px;
  margin-top: 8px;
  margin-left: 32px;
}
::v-deep .ant-checkbox-group-item {
  display: block;
  margin-bottom: 8px;
}
::v-deep .ant-btn-link {
  height: auto;
}
</style>
