<template>
  <div class="download">
    <a-button type="primary" @click="openModal">下载表格</a-button>
    <a-modal
      v-model="visible"
      title="下载表格"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancle="handleCancle"
    >
      <div class="modal-content">
        <div class="modal-title">选择细目表</div>
        <template v-if="list.length">
          <div class="text-btns">
            <a-button type="link" @click="allCheck">全选</a-button>
            <a-button type="link" @click="clearCheck">取消选择</a-button>
          </div>
          <div v-for="(item, index) in list" :key="index" class="subject">
            <a-checkbox
              :checked="item.checkAll"
              :indeterminate="item.indeterminate"
              @change="
                (checkedValue) => onCheckAllChange(checkedValue, item, index)
              "
            >
              {{ item.name }}
            </a-checkbox>
            <div class="checkboxGroup">
              <a-checkbox-group
                v-model="item.checkedList"
                @change="(checkedValue) => onChange(checkedValue, item, index)"
              >
                <a-checkbox
                  v-for="(item, index) in roleList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-checkbox>
              </a-checkbox-group>
            </div>
          </div>
        </template>
        <no-data v-else message="暂无数据"></no-data>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  downloadFile,
  previewPaper,
  downloading,
} from "@/core/api/exam/twoWay";
export default {
  props: {
    paperList: { type: Array, default: () => [] },
    subjectList: {
      type: Array,
      default: () => [],
    },

    maxRole: {
      type: [Number, null, String],
      default: null,
    },
    roleId: {
      type: [Number, null, String],
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      list: [],
      confirmLoading: false,
      timer: null,
      roleList: [
        { label: "备课组", value: 0 },
        { label: "教师", value: 1 },
        { label: "学生", value: 2 },
      ],
    };
  },
  mounted() {},
  methods: {
    allCheck() {
      this.list = this.list.map((item) => ({
        ...item,
        checkAll: true,
        checkedList: this.roleList.map((item) => item.value),
      }));
    },
    clearCheck() {
      this.list = this.list.map((item) => ({
        ...item,
        checkAll: false,
        checkedList: [],
        indeterminate: false,
      }));
    },
    onCheckAllChange(e, item, index) {
      this.list[index].checkAll = e.target.checked;
      if (e.target.checked) {
        this.list[index].checkedList = this.roleList.map((item) => item.value);
      } else {
        this.list[index].checkedList = [];
      }
      this.list[index].indeterminate = false;
      this.$forceUpdate();
    },
    onChange(e, item, index) {
      this.list[index].indeterminate =
        this.list[index].checkedList.length > 0 &&
        this.list[index].checkedList.length < this.roleList.length;
      this.list[index].checkAll =
        this.list[index].checkedList.length === this.roleList.length;
      this.$forceUpdate();
    },
    handleCancle() {
      clearTimeout(this.timer);
      this.timer = null;
    },
    async handleOk() {
      // this.visible = false;
      clearTimeout(this.timer);
      this.timer = null;
      let result = this.list.filter((item) => item.checkedList.length);
      let params = result.map((item) => ({
        examPaperId: item.paperId,
        type: item.checkedList.join(","),
      }));
      if (params.length) {
        try {
          this.confirmLoading = true;
          const res = await downloadFile(params);
          const { name, url } = res.data.data;
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
          this.confirmLoading = false;
        } catch {
          this.confirmLoading = false;
        }
      } else {
        this.$message({
          type: "success",
          message: "请至少选择一项",
          showClose: true,
        });
      }
    },
    async getPreviewPaper() {
      if ([1, 2].includes(this.maxRole) && this.roleId == 5) {
        this.roleList = this.roleList.filter((item) => item.label !== "备课组");
      }
      let rolePaperIds = this.subjectList.map((item) => item.paperId);

      const res = await previewPaper({ examId: this.$route.query.examId });
      let arr = res.data.data
        .filter(
          (item) =>
            item.ifPreview === 1 && rolePaperIds.includes(item.examPaperId)
        )
        .map((item) => item.examPaperId);
      let result = this.paperList.filter((item) => arr.includes(item.paperId));
      this.list = result.map((item) => ({
        ...item,
        checkAll: true,
        checkedList: [...this.roleList.map((item) => item.value)],
        indeterminate: false,
      }));
      this.visible = true;
    },
    async getDownloadStatus() {
      const res = await downloading({ examId: this.$route.query.examId });
      this.confirmLoading = res.data.data;
      if (res.data.data) {
        this.timer = setTimeout(() => {
          this.getDownloadStatus();
        }, 2500);
      } else {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    openModal() {
      this.getPreviewPaper();
      this.getDownloadStatus();
    },
  },
};
</script>

<style lang="scss" scoped>
.download {
  margin-left: 8px;
}

.modal-title {
  color: #0a1119;
  margin-bottom: 6px;
}

.text-btns {
  ::v-deep .ant-btn-link {
    padding: 0;
    height: auto;
  }

  .ant-btn-link + .ant-btn-link {
    margin-left: 8px;
  }

  margin-bottom: 15px;
}

::v-deep .ant-checkbox-wrapper {
  margin-bottom: 5px;
}

.checkboxGroup {
  margin-left: 18px;

  .ant-checkbox-wrapper {
    display: block;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}

.modal-content {
  height: 300px;
  overflow-y: auto;
}

::v-deep .ant-modal-body {
  padding: 18px 24px !important;
}
</style>
