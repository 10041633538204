<template>
  <div class="knowledgePoint">
    <div class="pointList">
      <div v-for="(item, index) in tagList" :key="index" class="point">
        {{ item.name }}

        <a-icon
          style="marign-left: 4px"
          type="close"
          @click="closeItem(item)"
        />
      </div>
      <div class="addBtn" @click="openModal">
        <a-icon type="plus" style="margin-right: 4px" />添加知识点
      </div>
    </div>
    <a-modal
      v-model="visible"
      title="添加知识点"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
    >
      <a-input-search placeholder="搜索" @change="onChange"></a-input-search>
      <div class="treeBox">
        <a-tree
          ref="tree"
          v-model="checkedKeys"
          checkable
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :tree-data="treeData"
          check-strictly
          :replace-fields="{
            children: 'children',
            title: 'name',
            key: 'id',
          }"
          @expand="onExpand"
          @select="onSelect"
        />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({ ids: "" }),
    },
    examPaperId: {
      type: [String, Number, null],
      default: null,
    },
    treeData: {
      type: Array,
      default: () => [],
    },
    treeDataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      searchValue: "",
      tagList: [],
      loading: false,
      confirmLoading: false,
      pointList: [],
    };
  },
  watch: {
    value: {
      deep: true,
      handler() {
        if (this.value.names) {
          let names = this.value.names.split(",");
          this.tagList = names.map((item) => ({
            name: item,
          }));
        } else {
          this.tagList = [];
        }
      },
    },
  },
  mounted() {
    if (this.value.names) {
      let names = this.value.names.split(",");
      this.tagList = names.map((item) => ({
        name: item,
      }));
      this.checkedKeys = [...this.value.ids.split(",").map(Number)];
    }
  },
  methods: {
    onSelect(selectedKeys, e) {
      let data = e.node.eventKey;
      if (this.checkedKeys.checked) {
        if (this.checkedKeys.checked.includes(data)) {
          this.checkedKeys.checked = this.checkedKeys.checked.filter(
            (item) => item !== data
          );
        } else {
          this.checkedKeys.checked = [...this.checkedKeys.checked, data];
        }
      } else {
        if (this.checkedKeys.includes(data)) {
          this.checkedKeys = this.checkedKeys.filter((item) => item !== data);
        } else {
          this.checkedKeys = [...this.checkedKeys, data];
        }
      }
    },
    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    getParentKey(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.id === key)) {
            parentKey = node.id;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },
    onChange(e) {
      const value = e.target.value;
      const expandedKeys = this.treeDataList
        .map((item) => {
          if (item.name.indexOf(value) > -1) {
            return this.getParentKey(item.id, this.treeData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },
    closeItem({ name }) {
      let checkedKeys = this.checkedKeys;
      if (this.checkedKeys.checked) {
        checkedKeys = this.checkedKeys.checked;
      }
      let id = this.treeDataList.find((item) => item.name === name).id;
      let data = checkedKeys.filter((item) => item != id);
      this.checkedKeys = [...data];
      this.$emit("change", data.join(","));
    },
    openModal() {
      this.visible = true;
    },
    handleOk() {
      this.visible = false;
      if (this.checkedKeys.checked) {
        this.$emit("change", this.checkedKeys.checked.join(","));
      } else {
        this.$emit("change", this.checkedKeys.join(","));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.knowledgePoint {
  .pointList {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    grid-gap: 4px;

    .point {
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      background: #f5f7fa;
      border-radius: 4px;
      padding: 0 8px;

      i {
        cursor: pointer;
      }
    }

    .addBtn {
      width: 106px;
      height: 32px;
      background-color: #f2f6ff;
      color: #2474ed;
      line-height: 32px;
      border-radius: 4px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s linear;

      &:hover {
        background-color: #2474ed;
        color: #f2f6ff;
      }
    }
  }
}

.treeBox {
  height: 450px;
  overflow-y: auto;
}
</style>
