import request from "@/core/services/axios";
export function specificationList(params) {
  // 双向细目表--预设表查询
  return request({
    url: "/resource/specification/list",
    method: "get",
    params: params,
  });
}
export function specificationTeacher(params) {
  // 双向细目表--查询教师
  return request({
    url: "/resource/specification/teacher",
    method: "get",
    params: params,
  });
}
export function specificationSaveTeacher(params) {
  // 双向细目表--设置教师\清空教师
  return request({
    url: "/resource/specification/teacher/save",
    method: "post",
    data: params,
  });
}
export function questiontypeList(params) {
  // 双向细目表--获取题型--学科网
  return request({
    url: "/resource/specification/questiontype",
    method: "get",
    params: params,
  });
}
export function savePlate(params) {
  // 双向细目表--保存版块
  return request({
    url: "/resource/specification/plate/save",
    method: "post",
    data: params,
  });
}
export function getPlate(params) {
  // 双向细目表--获取已保存版块
  return request({
    url: "/resource/specification/plate",
    method: "get",
    params: params,
  });
}
export function batchlate(params) {
  // 双向细目表--获取已保存失分原因
  return request({
    url: "specification/batch/plate",
    method: "get",
    params: params,
  });
}

export function getQuestiongroup(params) {
  // 双向细目表--获取已保存题组
  return request({
    url: "/resource/specification/questiongroup",
    method: "get",
    params: params,
  });
}
export function saveQuestiongroup(params) {
  // 双向细目表--保存题组
  return request({
    url: "/resource/specification/questiongroup/save",
    method: "post",
    data: params,
  });
}
export function getTree(params) {
  // 树形结构查询知识点
  return request({
    url: "/resource/specification/list/tree",
    method: "get",
    params: params,
  });
}
export function getAbility(params) {
  // 双向细目表--根据examPaperId获取能力层次
  return request({
    url: "/resource/specification/ability",
    method: "get",
    params: params,
  });
}
export function getAnalysis(params) {
  // 双向细目表--根据examPaperId获取失分原因分析
  return request({
    url: "/resource/specification/analysis",
    method: "get",
    params: params,
  });
}
export function saveDetail(params) {
  // 双向细目表--保存明细
  return request({
    url: "/resource/specification/detail/save",
    method: "post",
    data: params,
  });
}
export function saveReason(params) {
  // 双向细目表--保存失分原因分析
  return request({
    url: "/resource/specification/analysis/save",
    method: "post",
    data: params,
  });
}

export function delPlate(params) {
  // 双向细目表--删除版块
  return request({
    url: "/resource/specification/plate/delete",
    method: "get",
    params: params,
  });
}
export function delQuestiongroup(params) {
  // 双向细目表--删除题组
  return request({
    url: "/resource/specification/questiongroup/delete",
    method: "get",
    params: params,
  });
}
export function getConfiguration(params) {
  // 双向细目表--细目表配置项回显
  return request({
    url: "/resource/specification/configuration",
    method: "get",
    params: params,
  });
}
export function saveConfiguration(params) {
  // 双向细目表--保存细目表配置项
  return request({
    url: "/resource/specification/configuration/save",
    method: "post",
    data: params,
  });
}
export function respecification(params) {
  // 双向细目表--细目总表查询 (查询是否生成细目表)
  return request({
    url: "/resource/specification/respecification",
    method: "get",
    params: params,
  });
}
export function preview(params) {
  // 双向细目表--预览细目表
  return request({
    url: "/resource/specification/preview",
    method: "get",
    params: params,
  });
}
export function previewList(params) {
  // 双向细目表--预览细目表
  return request({
    url: "/resource/specification/preview/list",
    method: "get",
    params: params,
  });
}
export function styleconfiguration(params) {
  // 双向细目表--获取样式及配置数据
  return request({
    url: "/resource/specification/styleconfiguration",
    method: "get",
    params: params,
  });
}
export function getRole(params) {
  // 双向细目表--获取样式及配置数据
  return request({
    url: "/resource/specification/role",
    method: "get",
    params: params,
  });
}
export function saveStyle(params) {
  // 双向细目表--保存样式
  return request({
    url: "/resource/specification/style/save",
    method: "post",
    data: params,
  });
}
export function saveSetData(params) {
  // 双向细目表--保存配置数据
  return request({
    url: "/resource/specification/dataconfiguration/save",
    method: "post",
    data: params,
  });
}
export function downloadFile(params) {
  // 双向细目表--下载
  return request({
    url: "/resource/specification/download",
    method: "post",
    data: params,
  });
}
export function previewPaper(params) {
  // 双向细目表--查询拥有权限的科目
  return request({
    url: "/resource/specification/preview/paper",
    method: "get",
    params: params,
  });
}
export function downloading(params) {
  // 双向细目表--轮询获取是否下载学生完毕
  return request({
    url: "/resource/specification/downloading",
    method: "get",
    params: params,
  });
}
