<template>
  <div class="container">
    <div class="operate tips">
      <div class="operate-role">
        <div class="roleList">
          <div
            v-for="(item, index) in roleList"
            :key="index"
            :class="[
              'operate-role-item',
              subIndex === item.value ? 'operate-role-item-active' : '',
            ]"
            @click="handleRoleClick(item, index)"
          >
            {{ item.label }}
          </div>
        </div>
        <div v-if="roleName == '教师'" class="classNumList">
          <div
            v-for="(item, index) in classNumList"
            :key="index"
            :class="['classNum', classNum === item.name ? 'active' : '']"
            @click="handleClassClick(item, index)"
          >
            {{ item.name }}
          </div>
        </div>
        <div v-if="subIndex == 2" class="tip-wrapper">
          <a-icon type="question-circle" theme="filled" />
          学生版《双向细目表》仅供预览样式，不支持按个人查看基本信息与成绩，故以“动态数据”显示。若需了解详情，请点击下载表格查看。
        </div>
      </div>
      <div class="operate-btn">
        <changeStyle
          :key="paperId + subIndex + 'changeStyle'"
          style="margin-right: 8px"
          :role="subIndex"
          :style-source="styleSource"
          :paper-id="paperId"
          @change="handleStyleChange"
        />
        <setData
          :key="paperId + subIndex + 'setData'"
          :role="subIndex"
          :style-type="styleType"
          :paper-id="paperId"
          style="margin-right: 8px"
          :question-type-list="questionTypeList"
          :data-configuration="dataConfiguration"
          @change="setDataChange"
        />
        <setting-reason
          v-if="
            analysisData.length && [0, 1].includes(subIndex) && styleType == 1
          "
          :exam-paper-id="paperId"
          :analysis-data="analysisData"
          :has-other="hasOther"
          :form-table-data="form.tableData"
          @update="getData"
        ></setting-reason>
      </div>
    </div>
    <div class="title">
      {{ dataSource.previewName }}
    </div>
    <div class="config">
      <template v-if="[0, 1].includes(Number(subIndex))">
        <div class="config-box">
          <div v-if="personType.includes(0)" class="config-item">
            备课组长：{{ people1 }}
          </div>
          <div v-if="personType.includes(1)" class="config-item">
            任课教师：
            <choosen
              v-model="teachers"
              :paper-id="paperId"
              :exam-information="examInformation"
              :type="3"
              title="请选择任课教师"
              disabled-filter
            />
            <!-- 2024.04.01 曹亚平产品经理修改需求将任课老师选择从预设细目表移动到预览细目表中，并且筛选项固定为考试信息相关 -->
          </div>
          <div v-if="personType.includes(5)" class="config-item">
            命题人：{{ people2 }}
          </div>
          <div v-if="personType.includes(6)" class="config-item">
            审题人：{{ people3 }}
          </div>
        </div>
      </template>
      <template v-else>
        <div class="config-box">
          <div v-if="personType.includes(2)" class="config-item">
            学生班级：<span class="dynamicData">动态数据</span>
          </div>
          <div v-if="personType.includes(3)" class="config-item">
            学生姓名：<span class="dynamicData">动态数据</span>
          </div>
          <div v-if="personType.includes(4)" class="config-item">
            学生学号：<span class="dynamicData">动态数据</span>
          </div>
        </div>
      </template>
    </div>
    <div class="table">
      <a-table
        :columns="columns"
        :data-source="form.tableData"
        bordered
        :row-key="(record, index) => index"
        :scroll="{ x: 'max-content' }"
        :pagination="false"
      >
        <template slot="reasonForLossOfPoints" slot-scope="text, record, index">
          <!-- <el-tooltip
            :disabled="subIndex != 2"
            content="学生版本暂不支持线上操作"
            placement="top"
            effect="dark"
          > -->
          <reasonVue
            v-if="analysisData.length"
            class="reasonVue"
            :source-data="analysisData"
            :reason-for-loss-of-points.sync="record.reasonForLossOfPoints"
            :other-reason.sync="record.otherReason"
            :disabled="subIndex == 2"
            :has-other="hasOther"
            @change="(value) => reasonChange(value, record, index)"
          />
          <!-- </el-tooltip> -->
        </template>
      </a-table>
    </div>
    <div class="btns">
      <a-button
        v-if="(roleId == 5 && ![1, 2].includes(maxRole)) || roleId != 5"
        type="primary"
        :loading="btnLoading"
        @click="reset"
        >重新配置</a-button
      >
      <downloadTable
        :paper-list="paperList"
        :subject-list="subjectList"
        :max-role="maxRole"
        :role-id="roleId"
      />
    </div>
  </div>
</template>

<script>
/*
 * @Author: wzl 创建
 * @Date: 2024-04-30
 * @Last Modified by: wzl
 * @param { groupBy } 根据该字段合并
 * @param { showField } 展示该字段

 */
import changeStyle from "./components/changeStyle.vue";
import setData from "./components/setData.vue";
import downloadTable from "./components/downloadTable.vue";
import choosen from "./components/choosen.vue";
import settingReason from "./components/settingReason.vue";
import {
  getAbility,
  getAnalysis,
  preview,
  styleconfiguration,
  previewList,
  saveReason,
  getConfiguration,
} from "@/core/api/exam/twoWay.js";
import reasonVue from "./components/reason.vue";
export default {
  components: {
    changeStyle,
    setData,
    downloadTable,
    reasonVue,
    choosen,
    settingReason,
  },
  props: {
    subjectList: {
      type: Array,
      default: () => [],
    },
    paperList: {
      type: Array,
      default: () => [],
    },
    paperId: {
      type: [Number, null],
      default: null,
    },
    subjectId: {
      type: [Number, null],
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    roleObj: {
      type: Object,
      default: () => {},
    },
    examInformation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mergeConfig: {},
      roleName: "",
      dataSource: { detailList: [] },
      dataSet: {},
      hasOther: false,
      menuList: [],
      menuListIds: [],
      subIndex: 0,
      roleList: [],
      roleEnmu: [
        {
          label: "备课组",
          disabled: false,
          value: 0,
        },
        {
          label: "教师",
          disabled: false,
          value: 1,
        },
        {
          label: "学生",
          disabled: false,
          value: 2,
        },
      ],
      text: "",
      styleType: 0,
      statType: 2,
      classNum: "",
      classNumList: [],
      form: {
        tableData: [],
      },

      columns: [
        {
          title: "题号",
          dataIndex: "questionNum",
          align: "center",
        },
        {
          title: "题型",
          dataIndex: "questionTypeName",
          align: "center",
        },

        {
          title: "分值",
          dataIndex: "score",
          align: "center",
        },
        {
          title: "预估难度系数",
          dataIndex: "preCoefficient",
          align: "center",
          width: 120,
        },
        {
          title: "实际难度系数",
          dataIndex: "relCoefficient",
          align: "center",
        },
        {
          title: "年级均分",
          dataIndex: "gradeAvgScore",
          align: "center",
        },
        {
          title: "年级得分率",
          dataIndex: "gradeScoreRate",
          align: "center",
          customRender: (text, record) => {
            if (record.gradeScoreRate !== null) {
              if (record.gradeScoreRate === 0) {
                return "0.0%";
              } else {
                return record.gradeScoreRate !== null
                  ? record.gradeScoreRate.toFixed(2) + "%"
                  : "";
              }
            }
          },
        },
        {
          title: "考察内容",
          dataIndex: "inspectionContentName",
          align: "center",
          width: 150,
        },

        {
          title: "失分原因分析",
          dataIndex: "reasonForLossOfPoints",
          align: "center",
          scopedSlots: { customRender: "reasonForLossOfPoints" },
        },
      ],
      people1: "",
      people2: "",
      people3: "",
      teachers: [],
      specificationId: null,
      plainOptions: [],
      analysisData: [],
      btnLoading: false,
      styleSource: [],
      dataConfiguration: [],
      questionTypeList: [
        {
          label: "内容版块",
          value: 0,
          col: {
            title: "内容版块",
            align: "center",
            dataIndex: "plateName",
          },
        },
        {
          label: "原题号",
          value: 2,
          col: {
            title: "原题号",
            align: "center",
            dataIndex: "questionNum",
          },
        },
        {
          label: "新题号",
          value: 3,
          col: {
            title: "新题号",
            align: "center",
            dataIndex: "newQuestionNum",
          },
        },
        {
          label: "题型",
          value: 1,
          col: {
            title: "题型",
            align: "center",
            dataIndex: "questionTypeName",
          },
        },

        {
          label: "版块总分",
          value: 4,
          col: {
            title: "版块总分",
            align: "center",
            dataIndex: "plateScore",
          },
        },
        {
          label: "分值",
          value: 5,
          col: {
            title: "分值",
            align: "center",
            dataIndex: "score",
          },
        },
      ],
      difficultTypeList: [
        {
          label: "预估难度系数/等级",
          value: 0,
          col: {
            title: "预估难度系数",
            align: "center",
            dataIndex: "preCoefficient",
          },
        },
        {
          label: "实际难度系数/等级",
          value: 1,
          col: {
            title: "实际难度系数",
            align: "center",
            dataIndex: "relCoefficient",
          },
        },
      ],
      scoreTypeList: [
        {
          label: "年级均分",
          value: 0,
          col: {
            title: "年级均分",
            align: "center",
            dataIndex: "gradeAvgScore",
          },
        },
        {
          label: "年级得分率",
          value: 1,
          col: {
            title: "年级得分率",
            align: "center",
            dataIndex: "gradeScoreRate",
            customRender: (text, record) => {
              if (record.gradeScoreRate !== null) {
                if (record.gradeScoreRate === 0) {
                  return "0.0%";
                } else {
                  return record.gradeScoreRate !== null
                    ? record.gradeScoreRate.toFixed(2) + "%"
                    : "";
                }
              }
            },
          },
        },
        {
          label: "班级均分",
          value: 2,
          col: {
            title: "班级均分",
            align: "center",
            dataIndex: "classAvgScore",
            customRender: (text, record) => {
              if (this.subIndex == 2) {
                return "动态数据";
              }

              return record.classAvgScore === null ? "" : record.classAvgScore;
            },
          },
        },
        {
          label: "班级得分率",
          value: 3,
          col: {
            title: "班级得分率",
            align: "center",
            dataIndex: "classScoreRate",
            customRender: (text, record) => {
              if (this.subIndex == 2) {
                return "动态数据";
              } else if (record.classScoreRate !== null) {
                if (record.classScoreRate === 0) {
                  return "0.0%";
                } else {
                  return [null, undefined].includes(record.classScoreRate)
                    ? ""
                    : record.classScoreRate.toFixed(2) + "%";
                }
              }
            },
          },
        },
        {
          label: "学生得分",
          value: 4,
          col: {
            title: "学生得分",
            align: "center",
            dataIndex: "studentScore",
            customRender: () => "动态数据",
          },
        },
        {
          label: "学生得分率",
          value: 5,
          col: {
            title: "学生得分率",
            align: "center",
            dataIndex: "studentScoreRate",
            customRender: () => "动态数据",
          },
        },
      ],
      analysisTypeList: [
        {
          label: "能力层次",
          value: 0,
        },
        {
          label: "考察内容",
          value: 1,
          col: {
            title: "考察内容",
            dataIndex: "inspectionContentName",
            align: "center",
            width: 150,
          },
        },

        {
          label: "失分原因分析",
          value: 2,
          col: {
            title: "失分原因分析",
            dataIndex: "reasonForLossOfPoints",
            align: "center",
            scopedSlots: { customRender: "reasonForLossOfPoints" },
          },
        },
      ],
      personType: [],
      questionType: [],
      difficultType: [],
      scoreType: [],
      analysisType: [],
      roleId: null,
      teacherRole: null,
      configuration: {},
      maxRole: null,
    };
  },
  // watch: {
  //   dataSource: {
  //     deep: true,
  //     handler() {
  //       // this.getAnalysisData();
  //     },
  //   },
  // },
  mounted() {
    this.getConfigurationData();
  },
  methods: {
    async getConfigurationData() {
      const res = await getConfiguration({ examPaperId: this.paperId });
      this.configuration = res.data.data;
      this.roleId = this.roleObj.roleId;
      this.teacherRole = this.roleObj.teacherRole;
      this.maxRole = this.roleObj.teacherRole
        ? Math.max(...this.roleObj.teacherRole.split(",").map(Number))
        : null;
      // 1-任课教师 2-班主任 3-备课组长 4-年级组长 5-校领导 6-宿管 11- 联考
      if ([1, 2, 3].includes(this.roleId)) {
        this.subIndex = 0;
        this.roleList = [...this.roleEnmu];
      } else if (this.roleId === 5) {
        let teacherRole = Math.max(...this.teacherRole.split(",").map(Number));
        if ([3, 4, 5].includes(teacherRole)) {
          this.subIndex = 0;
          this.roleList = [...this.roleEnmu];
        } else if ([1, 2].includes(teacherRole)) {
          this.roleName = "教师";
          this.subIndex = 1;
          this.roleList = [
            ...this.roleEnmu.filter((item) => item.label !== "备课组"),
          ];
        }
      }
      this.classNumList = this.roleObj.classNum
        .split(",")
        .map((item) => ({ name: item }));

      if (this.classNumList.length && this.roleName == "教师") {
        this.handleClassClick(this.classNumList[0]);
      }
      this.getAbilityData();
      this.getAnalysisData();
    },

    getAbilityCols() {
      return this.plainOptions.map((item) => {
        return {
          title: item.label,
          dataIndex: "abilityLevel-value" + Math.random().toString(),
          align: "center",
          customRender: (text, record) => {
            if (record.abilityLevel == item.value) {
              return <a-icon type="check" />;
            } else if (record.totalStatus) {
              return "-";
            }
            return "";
          },
        };
      });
    },
    setDataChange({
      personType,
      questionType,
      difficultType,
      scoreType,
      analysisType,
      update,
    }) {
      this.personType = personType;
      this.questionType = questionType;
      this.difficultType = difficultType;
      this.scoreType = scoreType;
      this.analysisType = analysisType;
      let col = [];
      if (this.styleType == 1) {
        if (this.subIndex == 1) {
          col = [
            ...this.classNumList.map((item, index) => {
              return {
                title: item.name,
                align: "center",
                children: [
                  {
                    title: "版块均分",
                    align: "center",
                    dataIndex: "avgScore" + index,
                    customRender: (text, record) => {
                      let value = "";
                      if (record.classNumScoreList) {
                        let classNum = record.classNumScoreList.find(
                          (it) => it.classNum === item.name
                        );
                        value = classNum ? classNum.avgScore : "";
                      }
                      if (this.styleSource[0].questionStyleType == 1) {
                        if (record["plateName"]) {
                          let { arr, targetIndex } = this.getSameField(
                            "plateName",
                            text,
                            record
                          );

                          let obj = {
                            children: value !== null ? value : "",
                            attrs: {},
                          };
                          if (index == targetIndex) {
                            obj.attrs.rowSpan = arr.length;
                          } else {
                            obj.attrs.rowSpan = 0;
                          }
                          return obj;
                        } else {
                          return value;
                        }
                      }
                      return value;
                    },
                  },
                ],
              };
            }),
          ];
        }
        this.columns = [
          ...this.formatterCol(questionType, this.questionTypeList),
          ...this.formatterCol(difficultType, this.difficultTypeList),
          ...this.formatterCol(scoreType, this.scoreTypeList),
          ...col,
          ...this.formatterCol(analysisType, this.analysisTypeList),
        ];
      } else {
        this.columns = [
          ...this.formatterCol(questionType, this.questionTypeList),
          ...this.formatterCol(difficultType, this.difficultTypeList),
          ...this.formatterCol(scoreType, this.scoreTypeList),
          ...this.formatterCol(analysisType, this.analysisTypeList),
        ];
      }
      if (update) {
        this.getStyleconfiguration();
      }
    },
    formatterCol(arr, colList) {
      let result = colList.filter((item) => arr.includes(item.value));
      let cols = result.map((item, index) => {
        let customRender = {};
        if (this.styleType == 0) {
          if (this.statType == 0) {
            // 按新题号统计
            customRender = {};
          } else if (this.statType == 1) {
            // 按新题号统计
            customRender = this.getMerge(item, index);
          }
        } else {
          if (this.statType == 0) {
            customRender = this.getMergePlate(item, index);
          } else if (this.statType == 1) {
            customRender = this.getMergeNewQuestionNum(item, index);
          }
        }
        return {
          ...item.col,
          ...customRender,
        };
      });
      return cols;
    },
    getSameField(field, text, record) {
      let targetIndex = this.form.tableData.findIndex(
        (item) => item[field] === record[field]
      );
      let arr = this.form.tableData.filter(
        (item) => item[field] === record[field]
      );
      return { targetIndex: targetIndex, arr: arr };
    },
    renderFunction({ groupBy, showField }, text, record, index) {
      let value = record[showField];
      if (
        [
          "newQuestionAvgRate",
          "gradeScoreRate",
          "plateAvgRate",
          "classScoreRate",
        ].includes(showField)
      ) {
        if (record[showField] !== null) {
          if (record[showField] === 0) {
            value = "0.0%";
          } else {
            value =
              record[showField] === null
                ? ""
                : record[showField].toFixed(2) + "%";
          }
        }
      }
      if (
        [
          "studentScoreRate",
          "studentScore",
          "classAvgScore",
          "classScoreRate",
        ].includes(showField) &&
        this.subIndex == 2
      ) {
        value = "动态数据";
      }

      if (record[groupBy]) {
        let { arr, targetIndex } = this.getSameField(groupBy, text, record);

        let obj = {
          children: value !== null ? value : "",
          attrs: {},
        };
        if (index == targetIndex) {
          obj.attrs.rowSpan = arr.length;
        } else {
          obj.attrs.rowSpan = 0;
        }
        return obj;
      } else {
        return value;
      }
    },
    getMerge(item) {
      const { col } = item;
      if (!col) {
        return {};
      }
      let params = {
        showField: null,
        groupBy: null,
      };
      let newQuestionStatistics = this.styleSource.length
        ? this.styleSource[0].newQuestionStatistics.split(",")
        : [];
      if (col.dataIndex == "newQuestionNum") {
        params = {
          groupBy: "newQuestionNum",
          showField: "newQuestionNum",
        };
      } else if (
        col.dataIndex == "score" &&
        newQuestionStatistics.includes("0")
      ) {
        params = {
          groupBy: "newQuestionNum",
          showField: "score",
        };
      } else if (col.dataIndex === "studentScore") {
        params = {
          groupBy: "newQuestionNum",
          showField: "studentScore",
        };
      } else if (col.dataIndex === "studentScoreRate") {
        params = {
          groupBy: "newQuestionNum",
          showField: "studentScoreRate",
        };
      }
      // else if (col.dataIndex === "preCoefficient") {
      //   params = {
      //     groupBy: "newQuestionNum",
      //     showField: "preCoefficient",
      //   };
      // } else if (col.dataIndex === "relCoefficient") {
      //   params = {
      //     groupBy: "newQuestionNum",
      //     showField: "relCoefficient",
      //   };
      // }
      else if (col.dataIndex == "classAvgScore") {
        params = {
          groupBy: "newQuestionNum",
          showField: "classAvgScore",
        };
      } else if (col.dataIndex == "classScoreRate") {
        params = {
          groupBy: "newQuestionNum",
          showField: "classScoreRate",
        };
      } else if (
        col.dataIndex == "gradeAvgScore" &&
        newQuestionStatistics.includes("1")
      ) {
        params = {
          groupBy: "newQuestionNum",
          showField: "gradeAvgScore",
        };
      } else if (
        col.dataIndex == "gradeScoreRate" &&
        newQuestionStatistics.includes("2")
      ) {
        params = {
          groupBy: "newQuestionNum",
          showField: "gradeScoreRate",
        };
      }
      if (params && params.groupBy && params.showField) {
        if (params.groupBy === "newQuestionNum") {
          return {
            customRender: (text, record, index) => {
              let value = record[params.showField];

              if (
                [
                  "newQuestionAvgRate",
                  "gradeScoreRate",
                  "plateAvgRate",
                  "classScoreRate",
                ].includes(params.showField)
              ) {
                if (record[params.showField] !== null) {
                  if (record[params.showField] === 0) {
                    value = "0.0%";
                  } else {
                    value =
                      record[params.showField] === null
                        ? ""
                        : record[params.showField].toFixed(2) + "%";
                  }
                }
              }
              if (
                this.subIndex == 2 &&
                [
                  "studentScoreRate",
                  "studentScore",
                  "classScoreRate",
                  "classAvgScore",
                ].includes(params.showField)
              ) {
                value = "动态数据";
              }
              let obj = {
                children: value !== null ? value : "",
                attrs: {
                  rowSpan: 0,
                },
              };
              if (record.newQuestionNum) {
                if (!this.mergeConfig[record.newQuestionNum]) {
                  return value;
                }
                const { dataSet } = this.mergeConfig[record.newQuestionNum];

                const currentData = dataSet.find((it) => it[0] == index);

                if (currentData) {
                  const firstData = currentData[2];
                  if (
                    [
                      "newQuestionAvgRate",
                      "gradeScoreRate",
                      "plateAvgRate",
                      "classScoreRate",
                    ].includes(params.showField)
                  ) {
                    obj.children = firstData[params.showField]
                      ? firstData[params.showField].toFixed(2) + "%"
                      : "";
                  } else {
                    obj.children = firstData[params.showField];
                  }
                  if (
                    this.subIndex == 2 &&
                    [
                      "studentScoreRate",
                      "studentScore",
                      "classScoreRate",
                      "classAvgScore",
                    ].includes(params.showField)
                  ) {
                    obj.children = "动态数据";
                  }
                  if (currentData[0] === index) {
                    obj.attrs.rowSpan = currentData[1];
                  } else {
                    obj.attrs.rowSpan = 0;
                  }
                  return obj;
                } else {
                  return obj;
                }
              } else {
                return value;
              }
            },
          };
        } else {
          return {
            customRender: (text, record, index) =>
              this.renderFunction(params, text, record, index),
          };
        }
      }
    },
    getMergePlate({ col }) {
      let params = {
        groupBy: null,
        showField: null,
      };
      let enmuObj = {
        plateName: {
          groupBy: "plateName",
          showField: "plateName",
        },
        plateScore: {
          groupBy: "plateName",
          showField: "plateScore",
        },
        studentScore: {
          groupBy: "plateName",
          showField: "studentScore",
        },
        studentScoreRate: {
          groupBy: "plateName",
          showField: "studentScoreRate",
        },

        // preCoefficient: {
        //   groupBy: "plateName",
        //   showField: "preCoefficient",
        // },
        // relCoefficient: {
        //   groupBy: "plateName",
        //   showField: "relCoefficient",
        // },
        // gradeAvgScore: {
        //   groupBy: "plateName",
        //   showField: "gradeAvgScore",
        // },
        // gradeScoreRate: {
        //   groupBy: "plateName",
        //   showField: "gradeScoreRate",
        // },
        reasonForLossOfPoints: {
          groupBy: "plateName",
          showField: "reasonForLossOfPoints",
        },
      };
      // if (this.subIndex == 2) {
      //   enmuObj.reasonForLossOfPoints = {
      //     groupBy: "plateName",
      //     showField: "reasonForLossOfPoints",
      //   };
      // }
      if (col) {
        params = enmuObj[col.dataIndex];

        if (params && params.groupBy && params.showField) {
          if (params.showField == "reasonForLossOfPoints") {
            return {};
          }
          return {
            customRender: (text, record, index) =>
              this.renderFunction(params, text, record, index),
          };
          // return {
          //   customRender: (text, record) => {
          //     let value = record[params.showField];
          //     if (
          //       [
          //         "newQuestionAvgRate",
          //         "gradeScoreRate",
          //         "plateAvgRate",
          //       ].includes(params.showField)
          //     ) {
          //       if (record.gradeScoreRate !== null) {
          //         if (record.gradeScoreRate === 0) {
          //           value = "0.0%";
          //         } else {
          //           value =
          //             record.gradeScoreRate === null
          //               ? ""
          //               : record.gradeScoreRate.toFixed(2) + "%";
          //         }
          //       }
          //     }
          //     return value;
          //   },
          // };
        }
      }
    },
    getMergeNewQuestionNum({ col }) {
      let params = {
        groupBy: null,
        showField: null,
      };
      let enmuObj = {
        plateName: {
          groupBy: "plateName",
          showField: "plateName",
        },
        newQuestionNum: {
          groupBy: "newQuestionNum",
          showField: "newQuestionNum",
        },
        score: {
          groupBy: "plateName",
          showField: "score",
          newQuestionStatistics: ["0"],
        },
        plateScore: {
          groupBy: "plateName",
          showField: "plateScore",
        },
        studentScore: {
          groupBy: "plateName",
          showField: "studentScore",
        },
        studentScoreRate: {
          groupBy: "plateName",
          showField: "studentScoreRate",
        },
        // preCoefficient: {
        //   groupBy: "plateName",
        //   showField: "preCoefficient",
        // },
        // relCoefficient: {
        //   groupBy: "plateName",
        //   showField: "relCoefficient",
        // },
        gradeAvgScore: {
          groupBy: "plateName",
          showField: "gradeAvgScore",
        },
        gradeScoreRate: {
          groupBy: "plateName",
          showField: "gradeScoreRate",
        },
        classScoreRate: {
          groupBy: "plateName",
          showField: "classScoreRate",
        },
        classAvgScore: {
          groupBy: "plateName",
          showField: "classAvgScore",
        },
        reasonForLossOfPoints: {
          groupBy: "plateName",
          showField: "reasonForLossOfPoints",
        },
      };
      // if (this.subIndex == 2) {
      //   enmuObj.reasonForLossOfPoints = {
      //     groupBy: "plateName",
      //     showField: "reasonForLossOfPoints",
      //   };
      // }
      if (col) {
        params = enmuObj[col.dataIndex];
        let newQuestionStatistics = this.styleSource.length
          ? this.styleSource[0].newQuestionStatistics.split(",")
          : [];
        if (col.dataIndex === "score") {
          if (!newQuestionStatistics.includes("0")) {
            params = {
              groupBy: null,
              showField: null,
            };
          }
        }
        if (params && params.groupBy && params.showField) {
          if (params.showField === "newQuestionNum") {
            return {
              customRender: (text, record, index) => {
                let value = record.newQuestionNum;
                let obj = {
                  children: value !== null ? value : "",
                  attrs: {
                    rowSpan: 0,
                  },
                };
                if (record.newQuestionNum) {
                  if (!this.mergeConfig[record.newQuestionNum]) {
                    return value;
                  }
                  const { dataSet } = this.mergeConfig[record.newQuestionNum];
                  const currentData = dataSet.find((it) => it[0] === index);

                  if (currentData) {
                    const firstData = currentData[2];
                    if (
                      this.subIndex == 2 &&
                      [
                        "studentScoreRate",
                        "studentScore",
                        "classScoreRate",
                        "classAvgScore",
                      ].includes(params.showField)
                    ) {
                      obj.children = "动态数据";
                    } else if (
                      [
                        "newQuestionAvgRate",
                        "gradeScoreRate",
                        "plateAvgRate",
                        "classScoreRate",
                      ].includes(params.showField)
                    ) {
                      obj.children = firstData[params.showField]
                        ? firstData[params.showField].toFixed(2) + "%"
                        : "";
                    } else {
                      obj.children = firstData[params.showField];
                    }

                    if (currentData[0] === index) {
                      obj.attrs.rowSpan = currentData[1];
                    } else {
                      obj.attrs.rowSpan = 0;
                    }
                  }
                  return obj;
                } else {
                  return value;
                }
              },
            };
          } else {
            if (params.showField == "reasonForLossOfPoints") {
              return {};
            }
            return {
              customRender: (text, record, index) =>
                this.renderFunction(params, text, record, index),
            };
          }
        }
      }
    },
    reset() {
      this.$confirm("继续此操作将重新配置细目表, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            this.btnLoading = true;
            await preview({ examPaperId: this.paperId });
            this.$emit("preview");
            this.btnLoading = false;
            this.$message({
              message: "提交成功",
              showClose: true,
              type: "success",
            });
          } catch {
            this.btnLoading = false;
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    splitArrayByIndices(array, indices) {
      return indices.reduce((segments, index, i) => {
        if (i === 0) {
          segments.push(array.slice(0, index));
        } else {
          segments.push(array.slice(indices[i - 1] + 1, index - 1));
        }
        return segments;
      }, []);
    },
    async getAnalysisData() {
      const res = await getAnalysis({ examPaperId: this.paperId });
      const subjectAnalysis = this.configuration.subjectAnalysis || "";
      const analysisCommonUse = this.configuration.analysisCommonUse || "";
      let arr = [
        ...analysisCommonUse.split(","),
        ...subjectAnalysis.split(","),
      ];

      let result = arr.some((item) => item == 7);
      if (arr.length === 1 && result) {
        this.hasOther = result;
      } else {
        this.hasOther = false;
        this.analysisData = res.data.data.filter((item) =>
          arr.includes(item.analysisType.toString())
        );
      }
      // this.getData();
    },
    async getAbilityData() {
      const res = await getAbility({ examPaperId: this.paperId });
      const subjectLevel = this.configuration.subjectLevel || "";
      const commonUse = this.configuration.commonUse || "";
      let commonUseArr = [...commonUse.split(",")];
      let subjectLevelArr = [...subjectLevel.split(",")];
      let hasCommon = res.data.data.filter((item) => item.subjectId == -1);
      let hasSubject = res.data.data.filter((item) => item.subjectId != -1);
      this.plainOptions = [
        ...hasCommon
          .filter((item) => commonUseArr.includes(item.abilityType.toString()))
          .map((item) => ({
            label: item.name,
            value: item.abilityType.toString(),
          })),
        ...hasSubject
          .filter((item) =>
            subjectLevelArr.includes(item.abilityType.toString())
          )
          .map((item) => ({
            label: item.name,
            value: item.abilityType.toString(),
          })),
      ];
      this.analysisTypeList[0].col = {
        title: "能力层次",
        dataIndex: "abilityLevel",
        align: "center",
        children: this.getAbilityCols(),
      };
      this.getStyleconfiguration();
    },
    async getStyleconfiguration() {
      const res = await styleconfiguration({
        examPaperId: this.paperId,
        type: this.subIndex,
        classNum: this.classNum,
      });
      this.styleSource = res.data.data.style;
      this.dataConfiguration = res.data.data.dataConfiguration;
      let dataSet = this.dataConfiguration.length
        ? this.dataConfiguration[0]
        : [];
      this.personType = dataSet.personType ? dataSet.personType.split(",") : [];
      this.questionType = dataSet.questionType
        ? dataSet.questionType.split(",")
        : [];
      this.difficultType = dataSet.difficultType
        ? dataSet.difficultType.split(",")
        : [];
      this.scoreType = dataSet.scoreType ? dataSet.scoreType.split(",") : [];
      this.analysisType = dataSet.analysisType
        ? dataSet.analysisType.split(",")
        : [];
      if (this.styleSource.length && this.dataConfiguration.length) {
        this.getData();
      } else {
        this.form.tableData = [];
        this.specificationId = null;
        this.people1 = "";
        this.people2 = "";
        this.people3 = "";
      }
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async getData() {
      try {
        this.loading = true;
        this.mergeConfig = {};
        let styleObj = this.styleSource.length ? this.styleSource[0] : {};
        this.styleType = styleObj.styleType;
        const res = await previewList({
          examPaperId: this.paperId,
          type: this.subIndex,
          styleFlag: styleObj.styleType,
          classNUm: this.classNum,
        });
        this.form.tableData = [];
        this.dataSource = { ...res.data.data };
        this.specificationId = this.dataSource.specificationId;
        if (this.styleType == 1) {
          let cols = [];
          if (this.subIndex == 0) {
            cols = [
              ...this.classNumList.map((item, index) => {
                return {
                  title: item.name,
                  align: "center",
                  dataIndex: "class" + index,
                  children: [
                    {
                      title: "版块均分",
                      align: "center",
                      dataIndex: "avgScore" + index,
                      customRender: (text, record, ind) => {
                        let value = "";
                        if (record.classNumScoreList) {
                          let classNum = record.classNumScoreList.find(
                            (it) => it.classNum === item.name
                          );
                          value = classNum ? classNum.avgScore : "";
                        }
                        if (styleObj.questionStyleType == 1) {
                          if (record["plateName"]) {
                            let { arr, targetIndex } = this.getSameField(
                              "plateName",
                              text,
                              record
                            );

                            let obj = {
                              children: value !== null ? value : "",
                              attrs: {},
                            };
                            if (ind == targetIndex) {
                              obj.attrs.rowSpan = arr.length;
                            } else {
                              obj.attrs.rowSpan = 0;
                            }
                            return obj;
                          } else {
                            return value;
                          }
                        }
                        return value;
                      },
                    },
                  ],
                };
              }),
            ];
          }
          this.columns = [
            ...this.formatterCol(this.questionType, this.questionTypeList),
            ...this.formatterCol(this.difficultType, this.difficultTypeList),
            ...this.formatterCol(this.scoreType, this.scoreTypeList),
            ...cols,
            ...this.formatterCol(this.analysisType, this.analysisTypeList),
          ];
        } else {
          this.columns = [
            ...this.formatterCol(this.questionType, this.questionTypeList),
            ...this.formatterCol(this.difficultType, this.difficultTypeList),
            ...this.formatterCol(this.scoreType, this.scoreTypeList),
            ...this.formatterCol(this.analysisType, this.analysisTypeList),
          ];
        }

        if (this.dataSource.teachers) {
          this.teachers = this.dataSource.teachers.filter(
            (item) => item.type == 3
          );
        }
        this.people1 = this.dataSource.teacherName1;
        this.people2 = this.dataSource.teacherName2;
        this.people3 = this.dataSource.teacherName3;
        if (this.dataSource.detailList) {
          this.form.tableData = [...this.dataSource.detailList];
          this.form.tableData.forEach((item, index) => {
            if (item.newQuestionNum) {
              let newQuestionNum = item.newQuestionNum;
              const merge = this.mergeConfig[newQuestionNum];
              if (!merge) {
                let firstData = this.dataSource.detailList.find(
                  (it) => it.newQuestionNum == item.newQuestionNum
                );
                this.mergeConfig[newQuestionNum] = {
                  newQuestionNum: newQuestionNum,
                  dataSet: [[index, 1, firstData]],
                };
              } else {
                if (index > 0) {
                  if (
                    this.dataSource.detailList[index - 1].newQuestionNum !=
                    item.newQuestionNum
                  ) {
                    let data = this.dataSource.detailList[index];
                    this.mergeConfig[newQuestionNum].dataSet.push([
                      index,
                      1,
                      data,
                    ]);
                  } else {
                    let length =
                      this.mergeConfig[newQuestionNum].dataSet.length;
                    let dataSetLast =
                      this.mergeConfig[newQuestionNum].dataSet[length - 1];
                    this.mergeConfig[newQuestionNum].dataSet[length - 1][1] =
                      dataSetLast[1] + 1;
                  }
                }
              }
            }
          });
          if (this.columns.length) {
            let result = this.questionType.some((item) =>
              [0, 1, 2, 3].includes(item)
            );
            let classNumScoreList = [];
            for (let key in this.dataSource.classPlateScoreAvg) {
              classNumScoreList.push({
                classNum: key,
                avgScore: this.dataSource.classPlateScoreAvg[key],
              });
              // await this.sleep(50);
            }
            let data = {
              questionTypeName: "-",
              newQuestionNum: "-",
              questionNum: "-",
              plateScore: this.dataSource.plateScoreAvg,
              score: this.dataSource.scoreAvg,
              preCoefficient: this.dataSource.preCoefficientAvg,
              relCoefficient: this.dataSource.relCoefficientAvg,
              gradeAvgScore: this.dataSource.gradeScoreAvg,
              gradeScoreRate: this.dataSource.gradeRateAvg,
              classAvgScore: this.dataSource.classScoreAvg,
              classScoreRate: this.dataSource.classRateAvg,
              studentScore: this.dataSource.studentScoreAvg,
              studentScoreRate: this.dataSource.studentRateAvg,
              classNumScoreList: classNumScoreList,
              totalStatus: 1,
              inspectionContentName: "-",
              reasonForLossOfPoints: "-",
            };
            if (result) {
              data[this.columns[0].dataIndex] = "合计";
            }
            this.form.tableData = [...this.form.tableData, data];
          }
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    reasonChange(value, record, index) {
      this.form.tableData[index].reasonForLossOfPoints = value.type;
      this.form.tableData[index].otherReason = value.text;
      let item = this.form.tableData[index];
      this.$forceUpdate();
      this.saveSimgleData(
        {
          id: item.id,
          reasonForLossOfPoints: value.type,
          otherReason: value.text,
          applyType: this.subIndex,
          examPaperId: this.paperId,
          classNum: this.subIndex == 1 ? this.classNum : null,
          sheetDetailId: record.sheetDetailId,
        },
        "dontGetData"
      );
    },
    async saveSimgleData(data, type) {
      // 单条数据保存 用于 表格单个组件change事件后 保存
      await saveReason(data);
      if (type !== "dontGetData") {
        this.getData();
      }
    },
    handleStyleChange({ styleType, statType, update }) {
      this.styleType = styleType;
      this.statType = statType;
      if (update) {
        this.getStyleconfiguration();
      }
    },
    handleRoleClick(item) {
      this.form.tableData = [];
      this.columns = [];
      this.specificationId = null;
      this.people1 = "";
      this.people2 = "";
      this.people3 = "";
      this.subIndex = item.value;
      this.roleName = item.label;
      this.form.tableData = [];
      if (this.classNumList.length && item.label === "教师") {
        this.handleClassClick(this.classNumList[0]);
      } else {
        this.classNum = null;
        this.getStyleconfiguration();
      }
    },
    goBack() {
      this.$router.push({ path: "/exam/exam" });
    },
    handleClassClick(item) {
      this.classNum = item.name;
      this.getStyleconfiguration();
    },
  },
};
</script>

<style lang="scss">
@mixin baseFlex($at: center) {
  display: flex;
  align-items: $at;
}

.container {
  // background-color: #fff;
  .operate {
    @include baseFlex(flex-start);
    justify-content: space-between;
    padding: 0 24px;

    .subjectList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .subject-item {
        box-sizing: border-box;
        padding: 0 18px;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        margin-right: 8px;
        cursor: pointer;
        height: 32px;
        line-height: 32px;

        &:hover {
          color: #2474ed;
          border-color: #2474ed;
        }
      }

      .extend {
        padding: 0 10px;
      }

      .active {
        color: #2474ed;
        border-color: #2474ed;
      }
    }

    .peoples {
      justify-content: flex-start;
      @include baseFlex;

      .people {
        justify-content: flex-start;
        @include baseFlex;
        margin-right: 24px;
      }
    }

    .operate-btn {
      @include baseFlex;
    }

    .operate-role {
      .roleList {
        @include baseFlex;

        .operate-role-item {
          border: 1px solid #d5d6db;
          border-radius: 4px;
          background: #ffffff;
          height: 32px;
          line-height: 32px;
          text-align: center;
          box-sizing: border-box;
          padding: 0 18px;
          cursor: pointer;
        }

        .operate-role-item-active {
          border-color: #2474ed;
          color: #2474ed;
        }

        .operate-role-item + .operate-role-item {
          margin-left: 8px;
        }
      }
    }
  }

  .table {
    box-sizing: border-box;
    padding: 0 16px;
  }

  .tips {
    background: #f9fafc;
    border-radius: 4px;
    padding: 12px 18px;
    margin: 24px 18px;
  }

  .tip-wrapper {
    margin-top: 14px;
    font-size: 12px;

    i {
      color: #2474ed;
      cursor: pointer;
      margin-right: 8px;
    }
  }

  .classNumList {
    margin-top: 15px;
    @include baseFlex();

    .classNum {
      margin-right: 24px;
      cursor: pointer;
    }

    .active {
      color: #2474ed;
    }
  }
}

.title {
  margin: 11px 0;
  font-size: 16px;
  color: #0a1119;
  text-align: center;
}

.config {
  @include baseFlex();
  justify-content: center;
  margin-bottom: 10px;
  box-sizing: border-box;
  padding: 0 18px;

  .config-box {
    @include baseFlex();
    justify-content: center;

    .config-item {
      font-weight: 400;
      display: flex;
      align-items: center;
      color: #3e4551;

      .dynamicData {
        color: #3e4551;
      }
    }

    .config-item + .config-item {
      margin-left: 40px;
    }
  }
}

.table {
  margin-bottom: 16px;
}

.btns {
  @include baseFlex();
  text-align: center;
  justify-content: center;
}

::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px !important;
}

::v-deep .ant-table-row-cell-break-word {
  padding: 6px 10px !important;
}
</style>
