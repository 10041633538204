<template>
  <div class="setData">
    <a-button @click="openModel">配置数据</a-button>
    <a-modal
      v-model="visible"
      :confirm-loading="confirmLoading"
      title="配置数据"
      :ok-button-props="{ props: { disabled: disabledBtn } }"
      @ok="handleOk"
    >
      <div class="title">应用于：{{ getRoleName }}</div>
      <div class="part">
        <div class="part-title">人员</div>
        <el-checkbox-group v-model="personType" @change="radioChange">
          <a-row>
            <a-col v-if="[0].includes(role)" :span="8">
              <el-checkbox :label="0"> 备课组长 </el-checkbox>
            </a-col>
            <a-col v-if="[1].includes(role)" :span="8">
              <el-checkbox :label="1"> 任课教师 </el-checkbox>
            </a-col>
          </a-row>
          <a-row v-if="[2].includes(role)">
            <a-col :span="8">
              <el-checkbox :label="2"> 学生姓名</el-checkbox>
            </a-col>
            <a-col :span="8">
              <el-checkbox :label="3"> 学生班级 </el-checkbox>
            </a-col>
            <a-col :span="8">
              <el-checkbox :label="4"> 学生学号 </el-checkbox>
            </a-col>
          </a-row>
          <a-row>
            <a-col v-if="[0, 1].includes(role)" :span="8">
              <el-checkbox :label="5"> 命题人 </el-checkbox>
            </a-col>
            <a-col v-if="[0, 1].includes(role)" :span="8">
              <el-checkbox :label="6"> 审题人 </el-checkbox>
            </a-col>
          </a-row>
        </el-checkbox-group>
      </div>
      <div class="part">
        <div class="part-title">题目</div>
        <el-checkbox-group v-model="questionType" @change="radioChange">
          <div class="checkboxBox">
            <el-checkbox v-if="styleType == 1" :label="0">
              内容版块
            </el-checkbox>
            <el-checkbox :label="1"> 题型 </el-checkbox>
            <el-checkbox :label="2"> 原题号 </el-checkbox>
            <el-checkbox :label="3"> 新题号 </el-checkbox>
            <el-checkbox v-if="styleType == 1" :label="4">
              版块总分
            </el-checkbox>
            <el-checkbox :label="5"> 分值 </el-checkbox>
          </div>
        </el-checkbox-group>
      </div>
      <div class="part">
        <div class="part-title">难度</div>
        <el-checkbox-group v-model="difficultType" @change="radioChange">
          <el-checkbox v-if="[0, 1].includes(role)" :label="0">
            预估难度系数/等级
          </el-checkbox>
          <el-checkbox :label="1"> 实际难度系数/等级 </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="part">
        <div class="part-title">得分</div>
        <el-checkbox-group v-model="scoreType" @change="radioChange">
          <a-row>
            <a-col :span="8">
              <el-checkbox :label="0"> 年级均分 </el-checkbox>
            </a-col>
            <a-col :span="8">
              <el-checkbox :label="1"> 年级得分率 </el-checkbox>
            </a-col>
          </a-row>
          <a-row>
            <a-col v-if="[1, 2].includes(role)" :span="8">
              <el-checkbox :label="2"> 班级均分 </el-checkbox>
            </a-col>
            <a-col :span="8">
              <el-checkbox v-if="[1, 2].includes(role)" :label="3">
                班级得分率
              </el-checkbox>
            </a-col>
          </a-row>
          <a-row v-if="styleType !== 1">
            <a-col v-if="[2].includes(role)" :span="8">
              <el-checkbox :label="4"> 学生得分 </el-checkbox>
            </a-col>
            <a-col v-if="[2].includes(role)" :span="8">
              <el-checkbox :label="5"> 学生得分率 </el-checkbox>
            </a-col>
          </a-row>
        </el-checkbox-group>
      </div>
      <div class="part">
        <div class="part-title">分析</div>
        <el-checkbox-group v-model="analysisType" @change="radioChange">
          <a-row>
            <a-col :span="8">
              <el-checkbox :label="0"> 能力层次 </el-checkbox>
            </a-col>
            <a-col :span="8">
              <el-checkbox :label="1"> 考察内容 </el-checkbox>
            </a-col>
            <a-col :span="8">
              <el-checkbox :label="2"> 失分原因分析 </el-checkbox>
            </a-col>
          </a-row>
        </el-checkbox-group>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { saveSetData } from "@/core/api/exam/twoWay";
export default {
  props: {
    role: {
      type: Number,
      default: 0,
      required: true,
    },
    paperId: {
      type: [Number, null],
      default: null,
    },
    styleType: {
      type: Number,
      default: 0,
    },
    questionTypeList: {
      type: Array,
      default: () => [],
    },
    dataConfiguration: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      personType: [],
      questionType: [],
      difficultType: [],
      scoreType: [],
      analysisType: [],
      configObj: {},
      confirmLoading: false,
      disabledBtn: false,
    };
  },
  computed: {
    getRoleName() {
      if (this.role === 0) {
        return "备课组";
      } else if (this.role === 1) {
        return "教师";
      } else {
        return "学生";
      }
    },
  },
  watch: {
    dataConfiguration: {
      deep: true,
      handler() {
        this.init();
        this.$emit("change", {
          update: false,
          personType: this.personType,
          questionType: this.questionType,
          difficultType: this.difficultType,
          scoreType: this.scoreType,
          analysisType: this.analysisType,
        });
      },
    },
  },
  methods: {
    init() {
      let value = this.dataConfiguration;
      this.configObj = value.length ? value[0] : {};
      this.personType = this.configObj?.personType.split(",").map(Number);
      this.questionType = this.configObj?.questionType.split(",").map(Number);
      this.scoreType = this.configObj?.scoreType.split(",").map(Number);
      this.analysisType = this.configObj?.analysisType.split(",").map(Number);
      this.difficultType = this.configObj?.difficultType.split(",").map(Number);
    },
    openModel() {
      this.visible = true;
      this.init();
      // if (this.disabledBtn) {
      // }
      this.disabledBtn = false;
    },
    radioChange() {
      if (
        !this.personType.length &&
        !this.questionType.length &&
        !this.difficultType.length &&
        !this.scoreType.length &&
        !this.analysisType.length
      ) {
        this.disabledBtn = true;
      } else {
        this.disabledBtn = false;
      }
    },
    async handleOk() {
      try {
        this.confirmLoading = true;
        await saveSetData({
          analysisType: this.analysisType.length
            ? this.analysisType.join(",")
            : null,
          difficultType: this.analysisType.length
            ? this.difficultType.join(",")
            : null,
          personType: this.analysisType.length
            ? this.personType.join(",")
            : null,
          questionType: this.analysisType.length
            ? this.questionType.join(",")
            : null,
          scoreType: this.analysisType.length ? this.scoreType.join(",") : null,
          applyType: this.role,
          styleFlag: this.configObj.styleFlag,
          ifUse: this.configObj.ifUse,
          examPaperId: this.paperId,
          specificationId: this.configObj.specificationId,
          classNum: this.$parent.classNum,
          id: this.configObj.id,
        });
        this.$message({
          message: "保存成功",
          type: "success",
          showClose: true,
        });
        this.confirmLoading = false;
        this.visible = false;
        this.$emit("change", {
          update: true,
          personType: this.personType,
          questionType: this.questionType,
          difficultType: this.difficultType,
          scoreType: this.scoreType,
          analysisType: this.analysisType,
        });
      } catch {
        this.confirmLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  color: #0a1119;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: left;
}
.part {
  margin-bottom: 5px;
  &-title {
    color: #0a1119;
    font-weight: 600;
    margin-bottom: 8px;
  }
}
::v-deep .ant-checkbox-group {
  width: 100%;
}
::v-deep .ant-checkbox-wrapper {
  margin-bottom: 5px;
}
.checkboxBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
::v-deep .ant-modal-body {
  padding: 18px 24px !important;
}
</style>
