<template>
  <div class="editQuestionNum">
    <a-button @click="openModal">更改题号</a-button>
    <a-modal
      v-model="visible"
      width="fit-content"
      :confirm-loading="confirmLoading"
      title="更改题号"
      @ok="handleOk"
    >
      <div class="tips">
        <a-icon
          type="question-circle"
          style="color: #2474ed; margin-right: 9px"
          theme="filled"
        />适用于对原始题号进行重命名或将多个题号进行组合命名。
      </div>
      <div class="subject">应用于：{{ $parent.subjectName }}</div>
      <a-button type="primary" style="margin-bottom: 16px" @click="add"
        >添加</a-button
      >
      <div class="operate-box">
        <div class="box table">
          <a-form-model ref="form" :model="form">
            <a-table
              :data-source="form.tableData"
              :columns="columns"
              :scroll="{ y: 295 }"
              :pagination="false"
              :custom-row="customRow"
              bordered
            >
              <template slot="newQuestionNum" slot-scope="text, record, index">
                <a-form-model-item
                  :prop="`tableData[${index}].newQuestionNum`"
                  :rules="rules.newQuestionNum"
                >
                  <a-input
                    v-model="record.newQuestionNum"
                    class="nameInput"
                    placeholder="未命名题号"
                  ></a-input>
                </a-form-model-item>
              </template>
              <template slot="questionNum" slot-scope="text, record, index">
                <a-form-model-item
                  :prop="`tableData[${index}].questionNum`"
                  :rules="{
                    validator: (rule, value, callback) =>
                      validateQuestionNum(rule, value, callback, record, index),
                    trigger: 'change',
                  }"
                >
                  <div ref="questionNumBox" class="questionNumBox">
                    <span
                      v-if="!record.questionNum && !record.questionNum.length"
                      >从题号列表中选择以添加</span
                    >
                    <template v-else>
                      <div
                        v-for="(item, ind) in record.questionNum"
                        :key="ind"
                        class="quesitonItem"
                        @click="delItem(item, index, ind)"
                      >
                        {{ item.questionNum }}
                        <a-icon style="margin-left: 2px" type="delete" />
                      </div>
                    </template>
                  </div>
                </a-form-model-item>
              </template>
              <template slot="actions" slot-scope="text, record, index">
                <a-button
                  type="link"
                  style="color: red"
                  @click="del(record, index)"
                  >删除</a-button
                >
              </template>
            </a-table>
          </a-form-model>
        </div>
        <div class="box questionList">
          <div class="header">原始题号列表</div>
          <div class="conetent">
            <div
              v-for="(item, index) in questionList"
              :key="index"
              class="questionNumItem"
              @click="handleSelect(item, index)"
            >
              {{ item.questionNum }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getQuestiongroup,
  saveQuestiongroup,
  delQuestiongroup,
} from "@/core/api/exam/twoWay";
export default {
  props: {
    examPaperId: {
      type: [String, Number, null],
      default: null,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    specificationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      columns: [
        {
          title: "新题号",
          dataIndex: "newQuestionNum",
          align: "center",
          scopedSlots: { customRender: "newQuestionNum" },
          width: "110px",
        },
        {
          title: "题号",
          dataIndex: "qustionNum",
          align: "center",
          scopedSlots: { customRender: "questionNum" },
        },
        {
          title: "操作",
          dataIndex: "actions",
          align: "center",
          width: "75px",
          scopedSlots: { customRender: "actions" },
        },
      ],
      form: {
        tableData: [],
      },
      rules: {
        newQuestionNum: { required: true, message: " ", trigger: "blur" },
      },
      currentRow: {},
      questionList: [],
      loading: false,
      confirmLoading: false,
    };
  },
  mounted() {},
  methods: {
    customRow(record, index) {
      return {
        on: {
          // 鼠标单击行
          click: (event) => {
            this.currentRow = {
              record: record,
              index: index,
            };
            event.currentTarget.parentNode
              .querySelectorAll("tr")
              .forEach((item) => {
                item.style.background = "white";
              });
            event.currentTarget.style.background = "#fff5d7";
          },
        },
      };
    },
    delItem(item, index, ind) {
      this.questionList.push(item);
      this.questionList.sort((a, b) => a - b);
      this.form.tableData[index].questionNum.splice(ind, 1);
    },
    handleSelect(item, ind) {
      const { index } = this.currentRow;
      if (index !== null && index !== undefined) {
        this.form.tableData[index].questionNum.push(item);
        this.questionList.splice(ind, 1);
      } else {
        this.$message({
          type: "warning",
          showClose: true,
          message: "请选择一个题号",
        });
      }
    },
    openModal() {
      this.visible = true;
      this.questionList = [...this.dataSource];
      this.getQuestiongroupData();
    },
    async getQuestiongroupData() {
      try {
        this.loading = true;
        const res = await getQuestiongroup({ examPaperId: this.examPaperId });
        let questionNums = res.data.data.map((item) => item.questionNum);
        this.questionList = this.questionList.filter(
          (item) => !questionNums.includes(item.questionNum)
        );
        let newQuestionNums = [
          ...new Set(res.data.data.map((item) => item.newQuestionNum)),
        ];
        this.form.tableData = newQuestionNums.flatMap((item) => {
          let arr = res.data.data.filter((it) => it.newQuestionNum === item);
          return {
            newQuestionNum: item,
            ids: arr.map((item) => item.id).join(","),
            questionNum: arr,
          };
        });
        if (this.form.tableData.length === 0) {
          this.add();
        } else {
          this.$nextTick(() => {
            let trs = document
              .getElementsByClassName("ant-table-tbody")[1]
              .querySelectorAll("tr");
            if (trs.length && this.form.tableData.length) {
              trs[0].style.background = "#fff5d7";
              this.currentRow = {
                record: this.form.tableData[0],
                index: 0,
              };
            }
          });
        }
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    add() {
      this.form.tableData.push({ questionNum: [], name: "" });
      if (this.form.tableData.length === 1) {
        this.$nextTick(() => {
          let trs = document
            .getElementsByClassName("ant-table-tbody")[1]
            .querySelectorAll("tr");
          if (trs.length && this.form.tableData.length) {
            trs[0].style.background = "#fff5d7";
            this.currentRow = {
              record: this.form.tableData[0],
              index: 0,
            };
          }
        });
      }
    },
    del(record, index) {
      this.$confirm("确定删除当前题组", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (record.questionNum.length) {
            this.questionList = [
              ...this.questionList,
              ...record.questionNum,
            ].sort((a, b) => a - b);
          }
          if (record.ids) {
            await delQuestiongroup({ ids: record.ids });
            this.form.tableData.splice(index, 1);
            this.$emit("change");
          } else {
            this.form.tableData.splice(index, 1);
          }
          if (index === this.currentRow.index) {
            this.currentRow = {};
          }
          this.$message({
            type: "success",
            message: "删除成功",
            showClose: true,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });

      // this.form.tableData.splice(index, 1);
    },
    handleOk() {
      // this.visible = false;
      if (!this.form.tableData.length) {
        this.$message({
          type: "error",
          message: "请先添加一个题号",
          showClose: true,
        });
        return false;
      }
      let data = this.form.tableData.map((item) => {
        let data = {
          examPaperId: this.examPaperId,
          specificationId: this.specificationId,
          sheetDetailIds: item.questionNum
            .map((it) => it.sheetDetailId)
            .join(","),
          newQuestionNum: item.newQuestionNum,
        };
        return {
          ...data,
        };
      });
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.confirmLoading = true;
            await saveQuestiongroup(data);
            this.confirmLoading = false;
            this.visible = false;
            this.$emit("change");
          } catch {
            this.confirmLoading = false;
          }
        }
      });
    },
    validateQuestionNum(rule, value, callback, record, index) {
      let questionNumBox = document.getElementsByClassName("questionNumBox");
      if (!record.questionNum.length) {
        questionNumBox[index].style.borderColor = "#f5222d";

        callback(new Error("  "));
      } else {
        questionNumBox[index].style.borderColor = "#f3f8ff";
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin baseFlex($ai: center, $jc: center) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
}

.editQuestionNum {
  margin-right: 8px;
}

.tips {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 19px;
  color: #262829;
}

.subject {
  margin: 16px 0;
  color: #0a1119;
  font-size: 14px;
  font-weight: 600;
}

.operate-box {
  @include baseFlex(flex-start, space-between);

  .box {
    border: 1px solid #dee0e7;
  }

  .box + .box {
    margin-left: 16px;
  }

  .table {
    width: 472px;
  }

  .questionList {
    height: 339px;
    width: 268px;
    overflow-y: scroll;

    .header {
      border-radius: 4px 4px 0 0;
      height: 44px;
      background: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      box-sizing: border-box;
      padding: 0 18px;
      color: #0a1119;
      font-size: 14px;
      font-weight: 600;
      line-height: 44px;
    }

    .conetent {
      box-sizing: border-box;
      padding: 18px;
      @include baseFlex(flex-start, flex-start);
      flex-wrap: wrap;

      .questionNumItem {
        min-width: 32px;
        height: 32px;
        margin-right: 8px;
        margin-bottom: 8px;
        color: #2474ed;
        background: #f2f6ff;
        cursor: pointer;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}

.questionNumBox {
  @include baseFlex(flex-start, flex-start);
  border: 1px solid transparent;
  width: 100%;
  min-height: 32px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 4px 11px;
  transition: all 0.3s;
  flex-wrap: wrap;
  grid-gap: 4px;
  span {
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.35);
  }
  .quesitonItem {
    min-width: 32px;
    height: 24px;
    padding: 0 4px;
    text-align: center;
    color: #2474ed;
    background: #f2f6ff;
    border-radius: 4px;
    cursor: pointer;
    line-height: 24px;
    font-size: 12px;
    user-select: none;
  }
}

::v-deep .ant-form-item {
  margin-bottom: 0 !important;
}

::v-deep .ant-form-explain {
  display: none !important;
}

::v-deep .ant-table-row td {
  padding: 4px !important;
}

::v-deep .ant-table-placeholder {
  height: 295px;
}
.nameInput {
  border-color: #fff;
}
.has-error {
  input {
    border-color: red !important;
  }
}
</style>
