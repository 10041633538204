<template>
  <div class="reason">
    <template v-if="reasonForLossOfPoints != '-'">
      <a-input
        v-if="hasOther"
        v-model="text"
        style="width: 80px"
        :disabled="disabled"
        @change="inputChange"
      ></a-input>
      <a-radio-group
        v-else
        v-model="value"
        :disabled="disabled"
        @change="onChange"
      >
        <a-radio
          v-for="(item, index) in sourceData"
          :key="index"
          :value="item.analysisType"
        >
          <span v-if="item.analysisType != 7">{{ item.name }}</span>
          <a-input
            v-if="item.analysisType == 7"
            v-model="text"
            style="width: 80px"
            :disabled="disabled"
            @change="inputChange"
          ></a-input>
        </a-radio>
      </a-radio-group>
    </template>
    <span v-else>-</span>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    hasOther: {
      type: Boolean,
      default: false,
    },
    sourceData: {
      type: Array,
      default: () => [],
    },
    reasonForLossOfPoints: {
      type: [String, Number, null],
      default: null,
    },
    otherReason: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      value: null,
      text: null,
      timer: null,
    };
  },
  watch: {
    reasonForLossOfPoints(val) {
      this.value = val === null ? null : Number(val);
    },
    otherReason(val) {
      this.text = val;
    },
  },
  mounted() {
    this.value =
      this.reasonForLossOfPoints !== null
        ? Number(this.reasonForLossOfPoints)
        : null;
    this.text = this.otherReason;
  },
  methods: {
    onChange() {
      let data = { type: this.value };
      if (this.text !== null && this.value == 7) {
        data.text = this.text;
      }
      this.$emit("change", { ...data });
    },

    inputChange() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (this.text) this.value = 7;
      this.timer = setTimeout(() => {
        let data = { type: this.value };
        if (this.text !== null && this.value == 7) {
          data.text = this.text;
        }
        this.$emit("change", { ...data });
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.reason {
}

::v-deep .ant-input {
  border: none;
  outline: none;
  border-bottom: 1px solid #0a1119;
  border-radius: 0;
}

::v-deep .ant-input:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  border-bottom: 1px solid #0a1119 !important;
}

::v-deep .ant-input-disabled {
  background-color: transparent !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
</style>
