<template>
  <div v-loading="loading">
    <div class="operate tips">
      <div class="peoples">
        <div class="people">
          命题人：
          <choosen
            v-model="people1"
            :paper-id="paperId"
            :type="0"
            title="请选择命题人"
          />
        </div>
        <div class="people">
          审题人：
          <choosen
            v-model="people2"
            :paper-id="paperId"
            :type="1"
            title="请选择审题人"
          />
        </div>
        <div class="people">
          备课组长：
          <choosen
            v-model="people3"
            :type="2"
            :paper-id="paperId"
            title="请选择备课组长"
          />
        </div>
      </div>
      <div class="operate-btn">
        <batchProcess
          :selected-rows="selectedRows"
          :question-type-list="questionTypeList"
          @del="batchDel"
          @change="batchProcessChange"
        />
        <plateDivision
          :exam-paper-id="paperId"
          :data-source="form.tableData"
          :specification-id="specificationId"
          @change="editChange"
        />
        <editQuestionNum
          :exam-paper-id="paperId"
          :data-source="form.tableData"
          :specification-id="specificationId"
          @change="editChange"
        />
        <configureTable
          :subject-name="subjectName"
          :paper-id="paperId"
          @change="configureTableChange"
        />
      </div>
    </div>
    <div class="table">
      <a-form-model ref="form" :model="form">
        <a-table
          :data-source="form.tableData"
          :columns="columns"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :scroll="{ x: 'max-content' }"
          bordered
          :pagination="false"
        >
          <template slot="questionType" slot-scope="text, record, index">
            <a-form-model-item
              :prop="`tableData[${index}].questionType`"
              :rules="{ required: true, message: '', trigger: 'change' }"
            >
              <a-select
                v-model="record.questionType"
                style="width: 150px"
                placeholder="请选择题型"
                @change="(e) => tableChange(e, record, index)"
              >
                <a-select-option
                  v-for="(item, index) in questionTypeList"
                  :key="index"
                  :value="item.id"
                >
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span> {{ item.name }}</span>
                    </template>
                    <span> {{ item.name }}</span>
                  </a-tooltip>
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </template>
          <template slot="newQuestionNum" slot-scope="text, record">
            <span v-if="record.newQuestionNum">{{
              record.newQuestionNum
            }}</span>
            <span v-else style="color: #dadbe0">{{ record.questionNum }}</span>
          </template>
          <template slot="preCoefficient" slot-scope="text, record, index">
            <a-form-model-item
              :prop="`tableData[${index}].preCoefficient`"
              :rules="{
                validator: (rule, value, callback) =>
                  validpreCoefficient(rule, value, callback, record, index),
                trigger: 'change',
              }"
            >
              <a-input-number
                v-model="record.preCoefficient"
                :min="0"
                style="width: 100%; width: 100px"
                placeholder="范围：0～1"
                :max="1"
                :step="0.1"
                @blur="(e) => tableChange(e, record, index)"
              />
            </a-form-model-item>
          </template>
          <template slot="abilityLevel" slot-scope="text, record, index">
            <a-form-model-item
              :prop="`tableData[${index}].abilityLevel`"
              :rules="{
                validator: (rule, value, callback) =>
                  validAbilityLevels(rule, value, callback, record, index),
                trigger: 'change',
              }"
            >
              <a-radio-group
                v-model="record.abilityLevel"
                class="ability"
                :options="plainOptions"
                @change="(e) => tableChange(e, record, index)"
              />
            </a-form-model-item>
          </template>
          <template slot="inspectionContent" slot-scope="text, record, index">
            <a-form-model-item :prop="`tableData[${index}].inspectionContent`">
              <knowledgePoint
                v-if="treeData.length"
                :tree-data="treeData"
                :tree-data-list="treeDataList"
                :exam-paper-id="paperId"
                :value="{
                  ids: record.inspectionContent,
                  names: record.inspectionContentName,
                }"
                class="knowledgePoint"
                @change="(val) => knowledgePointChange(val, record, index)"
              />
            </a-form-model-item>
          </template>
          <template
            slot="reasonForLossOfPoints"
            slot-scope="text, record, index"
          >
            <a-form-model-item
              :prop="`tableData[${index}].reasonForLossOfPoints`"
            >
              <el-tooltip
                class="item"
                effect="dark"
                content="当前不可选，请在生成细目表后操作"
                placement="top"
              >
                <reasonVue
                  class="reasonVue"
                  :source-data="analysisData"
                  disabled
                  :has-other="hasOther"
                />
              </el-tooltip>
            </a-form-model-item>
          </template>
        </a-table>
      </a-form-model>
    </div>
    <div class="btns">
      <!-- <a-button type="primary" @click="save">保存</a-button> -->
      <a-button type="primary" :loading="btnLoading" @click="create"
        >生成细目表</a-button
      >
    </div>
  </div>
</template>
<script>
import choosen from "./components/choosen.vue";
import plateDivision from "./components/plateDivision.vue";
import editQuestionNum from "./components/editQuestionNum.vue";
import configureTable from "./components/configureTable.vue";
import knowledgePoint from "./components/knowledgePoint.vue";
import reasonVue from "./components/reason.vue";
import batchProcess from "./components/batchProcess.vue";
import {
  questiontypeList,
  getAbility,
  getAnalysis,
  saveDetail,
  getTree,
  preview,
  getConfiguration,
} from "@/core/api/exam/twoWay";

export default {
  name: "TwoWayCheckList",
  components: {
    choosen,
    plateDivision,
    editQuestionNum,
    configureTable,
    knowledgePoint,
    reasonVue,
    batchProcess,
  },
  props: {
    paperId: {
      type: [Number, null],
      default: null,
    },
    subjectId: {
      type: [Number, null],
      default: null,
    },
    subjectName: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Object,
      default: () => ({
        teachers: [],
        specificationDetails: [],
      }),
    },
  },
  data() {
    return {
      questionTypeList: [],
      examInformation: {},
      subjectList: [],
      menuList: [],
      menuListIds: [],
      text: "",
      people1: [],
      people2: [],
      people3: [],
      people4: [],
      selectedRowKeys: [],
      form: {
        tableData: [],
      },
      selectedRows: [],
      columns: [],
      columnsEnmu: [
        {
          title: "版块",
          dataIndex: "plateName",
          align: "center",
          width: 75,
        },
        {
          title: "题号",
          dataIndex: "questionNum",
          align: "center",
          width: 60,
        },
        {
          title: "新题号",
          dataIndex: "newQuestionNum",
          align: "center",
          width: 75,
          scopedSlots: { customRender: "newQuestionNum" },
        },
        {
          title: "题型",
          dataIndex: "questionType",
          align: "center",
          scopedSlots: { customRender: "questionType" },
          width: 170,
        },

        {
          title: "预估难度系数",
          dataIndex: "preCoefficient",
          align: "center",
          width: 120,
          scopedSlots: { customRender: "preCoefficient" },
        },
        {
          title: "能力层次",
          dataIndex: "abilityLevel",
          align: "center",
          width: 301,
          scopedSlots: { customRender: "abilityLevel" },
        },
        {
          title: "考察内容",
          dataIndex: "inspectionContent",
          align: "center",
          width: 570,
          scopedSlots: { customRender: "inspectionContent" },
        },
        {
          title: "失分原因分析",
          dataIndex: "reasonForLossOfPoints",
          align: "center",
          width: 365,
          scopedSlots: { customRender: "reasonForLossOfPoints" },
        },
      ],
      plainOptions: [],
      loading: false,
      specificationId: null,
      analysisData: [],
      treeData: [],
      btnLoading: false,
      treeDataList: [],
      configuration: {},
      hasOther: false,
    };
  },
  watch: {
    dataSource: {
      deep: true,
      handler() {
        this.getData();
      },
    },
  },
  mounted() {
    this.getConfigurationData();

    this.getData();
  },
  methods: {
    async getConfigurationData() {
      const res = await getConfiguration({ examPaperId: this.paperId });
      this.configuration = res.data.data;
      this.getAbilityData();
      this.getAnalysisData();
    },
    tableChange(e, record, index) {
      let item = this.form.tableData[index];
      this.saveSimgleData([item]);
    },
    editChange() {
      this.$emit("change");
    },
    async knowledgePointChange(val, record, index) {
      let item = this.form.tableData[index];
      item.inspectionContent = val;
      await saveDetail([item]);
      this.$emit("change");
    },
    async getTreeData() {
      const res = await getTree({
        examPaperId: this.paperId,
        keyword: "",
      });
      this.treeData = res.data.data;
      this.generateList(this.treeData);
    },
    generateList(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const id = node.id;
        this.treeDataList.push({ id, name: node.name });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },
    async getAbilityData() {
      const res = await getAbility({ examPaperId: this.paperId });
      const subjectLevel = this.configuration.subjectLevel || "";
      const commonUse = this.configuration.commonUse || "";
      let commonUseArr = [...commonUse.split(",")];
      let subjectLevelArr = [...subjectLevel.split(",")];
      let hasCommon = res.data.data.filter((item) => item.subjectId == -1);
      let hasSubject = res.data.data.filter((item) => item.subjectId != -1);
      this.plainOptions = [
        ...hasCommon
          .filter((item) => commonUseArr.includes(item.abilityType.toString()))
          .map((item) => ({
            label: item.name,
            value: item.abilityType.toString(),
          })),
        ...hasSubject
          .filter((item) =>
            subjectLevelArr.includes(item.abilityType.toString())
          )
          .map((item) => ({
            label: item.name,
            value: item.abilityType.toString(),
          })),
      ];
      // let arr = [...commonUse.split(","), ...subjectLevel.split(",")];
      // this.plainOptions = res.data.data
      //   .filter((item) => arr.includes(item.abilityType.toString()))
      //   .map((item) => ({
      //     label: item.name,
      //     value: item.abilityType.toString(),
      //   }));
    },
    async getAnalysisData() {
      const res = await getAnalysis({ examPaperId: this.paperId });
      const subjectAnalysis = this.configuration.subjectAnalysis || "";
      const analysisCommonUse = this.configuration.analysisCommonUse || "";
      let arr = [
        ...analysisCommonUse.split(","),
        ...subjectAnalysis.split(","),
      ];
      this.analysisData = res.data.data.filter((item) =>
        arr.includes(item.analysisType.toString())
      );
      let result = arr.some((item) => item == 7);
      if (this.analysisData.length === 1 && result) {
        this.hasOther = result;
      } else {
        this.hasOther = false;
      }
    },

    getData() {
      this.form.tableData = JSON.parse(
        JSON.stringify(this.dataSource.specificationDetails)
      );
      this.specificationId = this.dataSource.specificationId;
      let data = [...this.columnsEnmu];
      if (!this.dataSource.ifPlate) {
        data = data.filter((item) => item.title !== "版块");
      }
      if (!this.dataSource.ifNewQuestionNum) {
        data = data.filter((item) => item.title !== "新题号");
      }
      this.columns = [...data];
      if (this.dataSource.teachers) {
        this.people1 = this.dataSource.teachers.filter(
          (item) => item.type == 0
        );
        this.people2 = this.dataSource.teachers.filter(
          (item) => item.type == 1
        );
        this.people3 = this.dataSource.teachers.filter(
          (item) => item.type == 2
        );
        this.people4 = this.dataSource.teachers.filter(
          (item) => item.type == 3
        );
      }

      this.getQuestiontypeList();
      this.getTreeData();
      this.loading = false;
    },
    async getQuestiontypeList() {
      const res = await questiontypeList({ examPaperId: this.paperId });
      this.questionTypeList = res.data.data;
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    async saveSimgleData(data) {
      // 单条数据保存 用于 表格单个组件change事件后 保存
      await saveDetail(data);
      // this.$emit("change");
    },
    async save() {
      let data = this.form.tableData.map((item) => {
        return {
          abilityLevel: item.abilityLevel,
          examPaperId: this.paperId,
          id: item.id,
          inspectionContent: item.inspectionContent,
          preCoefficient: item.preCoefficient,
          questionNum: item.questionNum,
          questionType: item.questionType,
          sheetDetailId: item.sheetDetailId,
          specificationId: this.specificationId,
        };
      });
      await saveDetail(data);
      this.$message({
        showClose: true,
        message: "保存成功",
        type: "success",
      });
    },
    create() {
      let result = this.form.tableData.every((item) => item.inspectionContent);
      if (!result) {
        this.$message({
          type: "error",
          message: "尚有题目未添加知识点，请检查!",
          showClose: true,
        });
        return false;
      }
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // 验证成功
          try {
            this.btnLoading = true;
            await preview({ examPaperId: this.paperId });
            this.btnLoading = false;
            this.$message({
              message: "提交成功",
              showClose: true,
              type: "success",
            });
            this.$emit("preview");
          } catch {
            this.btnLoading = false;
          }
        } else {
          this.backToError();
        }
      });
    },
    batchProcessChange(params) {
      const { questionNums, questionType } = params;
      this.form.tableData = this.form.tableData.map((item) => {
        if (questionNums.includes(item.questionNum)) {
          return {
            ...item,
            questionType: questionType,
          };
        }
        return item;
      });
      this.save();
    },
    batchDel({ index }) {
      this.selectedRows.splice(index, 1);
      this.selectedRowKeys.splice(index, 1);
    },
    configureTableChange() {
      this.getConfigurationData();
      this.$emit("change");
    },
    goBack() {
      this.$router.go(-1);
    },
    backToError() {
      const errorList = document.getElementsByClassName("has-error");
      let el = errorList.length ? errorList[0] : null;
      if (el) {
        this.$nextTick(() => {
          el.scrollIntoView(false, {
            behavior: "smooth",
          });
        });
      }
    },
    validAbilityLevels(rule, value, callback, record, index) {
      let ability = document.getElementsByClassName("ability");
      if (!record.abilityLevel) {
        callback(new Error(" "));
        ability[index].classList.add("isError");
      } else {
        ability[index].classList.remove("isError");
        callback();
      }
    },
    validpreCoefficient(rule, value, callback, record, index) {
      let data = this.form.tableData[index].preCoefficient;
      if (["", null, undefined].includes(data)) {
        callback(Error(" "));
      } else {
        callback();
      }
    },
    validReason(rule, value, callback, record, index) {
      let reason = document.getElementsByClassName("reasonVue");
      if (!record.reasonForLossOfPoints) {
        callback(new Error(" "));
        reason[index].classList.add("isError");
      } else {
        reason[index].classList.remove("isError");
        callback();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@mixin baseFlex() {
  display: flex;
  align-items: center;
}

.container {
  box-sizing: border-box;
  padding-bottom: 24px;
  background-color: #fff;
  margin-top: 24px;

  .operate {
    @include baseFlex;
    justify-content: space-between;
    padding: 0 24px;

    .subjectList {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .subject-item {
        box-sizing: border-box;
        padding: 0 18px;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        margin-right: 8px;
        cursor: pointer;
        height: 32px;
        line-height: 32px;

        &:hover {
          color: #2474ed;
          border-color: #2474ed;
        }
      }

      .extend {
        padding: 0 10px;
      }

      .active {
        color: #2474ed;
        border-color: #2474ed;
      }
    }

    .peoples {
      justify-content: flex-start;
      @include baseFlex;
      flex-wrap: wrap;

      .people {
        justify-content: flex-start;
        @include baseFlex;
        margin-right: 24px;
      }
    }

    .operate-btn {
      @include baseFlex;
    }
  }

  .table {
    box-sizing: border-box;
    padding: 0 16px;
  }

  .tips {
    background: #f9fafc;
    border-radius: 4px;
    padding: 16px 18px;
    margin: 24px 18px;
  }
}

.btns {
  text-align: center;
  margin-top: 24px;

  .ant-btn + .ant-btn {
    margin-left: 8px;
  }
}

::v-deep .ant-form-item {
  margin-bottom: 0;
}

::v-deep .ant-form-explain {
  display: none !important;
}

.isError {
  ::v-deep .ant-radio-wrapper {
    color: red !important;
  }
}

.knowledgePointError {
  position: relative;
  transition: all 0.3 linear;

  &::after {
    position: absolute;
    left: 0;
    content: "请添加知识点";
    top: 105%;
    white-space: nowrap;
    color: red;
    line-height: 1;
    font-size: 12px;
  }
}

::v-deep .ant-form-item-control {
  text-align: left;
}

::v-deep .ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px !important;
}

::v-deep .ant-table-row-cell-break-word {
  padding: 6px 10px !important;
}
</style>
