<template>
  <div v-loading="loading" class="configure">
    <a-button @click="openModal">配置细目表项</a-button>
    <a-modal v-model="visible" title="配置细目表项" @ok="handleOk">
      <div class="mb16">应用于：{{ $parent.subjectName }}</div>
      <div class="part">
        <div class="title">分类</div>
        <a-checkbox v-model="form.ifPlate" :true-value="1" :false-value="0"
          >版块</a-checkbox
        >
      </div>
      <div class="part">
        <div class="title">难度</div>
        <div class="part-item">
          <div class="checkbox">
            <a-checkbox v-model="form.ifPreDifficult">预估难度</a-checkbox>
          </div>
          <difficulty
            :form="form"
            :title="'预估难度'"
            :disabled="!form.ifPreDifficult"
            :fileds="['preCoefficient', 'preLevel']"
            @change="estimatedDifficultyChange"
          />
        </div>
        <div class="part-item">
          <div class="checkbox">
            <a-checkbox v-model="form.ifRelDifficult">实际难度</a-checkbox>
          </div>
          <difficulty
            :form="form"
            :title="'实际难度'"
            :disabled="!form.ifRelDifficult"
            :fileds="['relCoefficient', 'relLevel']"
            @change="realDifficultyChange"
          />
        </div>
      </div>
      <div class="part">
        <div class="title">分析</div>
        <div class="part-item">
          <div class="checkbox">
            <a-checkbox v-model="form.ifAbilityLevel">能力层次</a-checkbox>
          </div>
          <ability
            ref="ability"
            :disabled="!form.ifAbilityLevel"
            :title="'能力层次'"
            :paper-id="paperId"
            :form="form"
            :subject-name="subjectName"
            @change="abilityLevelsChange"
            @reset="reset"
          />
        </div>
        <div class="part-item">
          <div class="checkbox">
            <a-checkbox v-model="form.ifInspection">考察内容</a-checkbox>
          </div>
          <contentVue
            :disabled="!form.ifInspection"
            :title="'失分原因分析'"
            @change="contentChange"
          />
        </div>
        <div class="part-item">
          <div class="checkbox">
            <a-checkbox v-model="form.ifLoseAnalysis">失分原因分析</a-checkbox>
          </div>
          <ability
            ref="ability"
            :disabled="!form.ifLoseAnalysis"
            :title="'失分原因分析'"
            :form="form"
            :paper-id="paperId"
            @change="reasonChange"
            @reset="reset"
          />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ability from "./ability.vue";
import contentVue from "./content.vue";
import difficulty from "./difficulty.vue";
import { getConfiguration, saveConfiguration } from "@/core/api/exam/twoWay";
export default {
  components: {
    difficulty,
    ability,
    contentVue,
  },
  props: {
    subjectName: {
      type: String,
      default: "",
    },
    paperId: {
      type: [Number, String, null],
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      loading: false,
      form: {
        ifLoseAnalysis: true,
        ifAbilityLevel: true,
        ifInspection: true,
        ifPlate: false,
        ifPreDifficult: true,
        ifRelDifficult: true,
      },
    };
  },
  mounted() {},
  methods: {
    async reset() {
      const res = await getConfiguration({ examPaperId: this.paperId });
      let { data } = res.data;
      this.form = {
        ...this.form,
        ifLoseAnalysis: data.ifLoseAnalysis == 1,
        ifAbilityLevel: data.ifAbilityLevel == 1,
        ifInspection: data.ifInspection == 1,
        ifPlate: data.ifPlate == 1,
        ifPreDifficult: data.ifPreDifficult == 1,
        ifRelDifficult: data.ifRelDifficult == 1,
      };
      this.$refs.ability.init();
    },
    async openModal() {
      try {
        this.loading = true;
        const res = await getConfiguration({ examPaperId: this.paperId });
        let { data } = res.data;
        this.form = {
          ...res.data.data,
          ifLoseAnalysis: data.ifLoseAnalysis == 1,
          ifAbilityLevel: data.ifAbilityLevel == 1,
          ifInspection: data.ifInspection == 1,
          ifPlate: data.ifPlate == 1,
          ifPreDifficult: data.ifPreDifficult == 1,
          ifRelDifficult: data.ifRelDifficult == 1,
        };
        this.visible = true;
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async handleOk() {
      let data = {
        ...this.form,
        ifLoseAnalysis: Number(this.form.ifLoseAnalysis),
        ifAbilityLevel: Number(this.form.ifAbilityLevel),
        ifInspection: Number(this.form.ifInspection),
        ifPlate: Number(this.form.ifPlate),
        ifPreDifficult: Number(this.form.ifPreDifficult),
        ifRelDifficult: Number(this.form.ifRelDifficult),
        examPaperId: this.paperId,
      };
      await saveConfiguration(data);
      this.$message({
        showClose: true,
        message: "配置成功",
        type: "success",
      });
      this.$emit("change");
      this.visible = false;
    },
    contentChange() {},
    estimatedDifficultyChange(value) {
      this.form = {
        ...this.form,
        ...value,
      };
    },
    realDifficultyChange(value) {
      this.form = {
        ...this.form,
        ...value,
      };
    },
    abilityLevelsChange({ commonUse, subjectLevel }) {
      this.form.commonUse = commonUse;
      this.form.subjectLevel = subjectLevel;
    },
    reasonChange(value) {
      this.form.analysisCommonUse = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.configure {
  font-size: 14px;
  color: #0a1119;
}
.mb16 {
  margin-bottom: 16px;
}
.mb24 {
  margin-bottom: 24px;
}
.part {
  margin-bottom: 24px;
  .title {
    font-weight: 600;
    margin-bottom: 8px;
    text-align: left;
    font-size: 14px;
  }
  .part-item {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .checkbox {
      width: 30%;
    }
  }
}
</style>
