<template>
  <div class="plateDivision">
    <a-button @click="openModal">划分版块</a-button>
    <a-modal
      v-model="visible"
      width="fit-content"
      :confirm-loading="confirmLoading"
      title="划分版块"
      @ok="handleOk"
    >
      <div v-loading="loading">
        <div class="subject">应用于：{{ $parent.subjectName }}</div>
        <a-button type="primary" style="margin-bottom: 16px" @click="add"
          >添加</a-button
        >
        <div class="operate-box">
          <div class="box table">
            <a-form-model ref="form" :model="form">
              <a-table
                ref="table"
                :data-source="form.tableData"
                :columns="columns"
                bordered
                :scroll="{ y: 295 }"
                :pagination="false"
                :custom-row="customRow"
              >
                <template slot="sort" slot-scope="text, record, index">
                  <a-form-model-item
                    :prop="`tableData[${index}].sort`"
                    :rules="{
                      validator: (rule, value, callback) =>
                        validSort(rule, value, callback, record, index),
                      trigger: ['blur', 'change'],
                    }"
                  >
                    <a-input
                      v-model.number="record.sort"
                      class="nameInput"
                      placeholder="排序"
                    ></a-input>
                  </a-form-model-item>
                </template>
                <template slot="name" slot-scope="text, record, index">
                  <a-form-model-item
                    :prop="`tableData[${index}].name`"
                    :rules="{
                      validator: (rule, value, callback) =>
                        validPlateName(rule, value, callback, record, index),
                      trigger: ['blur', 'change'],
                    }"
                  >
                    <a-input
                      v-model="record.name"
                      class="nameInput"
                      placeholder="未命名模块"
                    ></a-input>
                  </a-form-model-item>
                </template>
                <template slot="questionNum" slot-scope="text, record, index">
                  <a-form-model-item
                    :prop="`tableData[${index}].questionNum`"
                    :rules="{
                      validator: (rule, value, callback) =>
                        validateQuestionNum(
                          rule,
                          value,
                          callback,
                          record,
                          index
                        ),
                      trigger: 'change',
                    }"
                  >
                    <div ref="questionNumBox" class="questionNumBox">
                      <span
                        v-if="record.questionNum && !record.questionNum.length"
                        >从题号列表中选择以添加</span
                      >
                      <template v-else>
                        <div
                          v-for="(item, ind) in record.questionNum"
                          :key="ind"
                          class="quesitonItem"
                          @click="delItem(item, index, ind)"
                        >
                          {{ item.questionNum }}
                          <a-icon type="delete" />
                        </div>
                      </template>
                    </div>
                  </a-form-model-item>
                </template>
                <template slot="actions" slot-scope="text, record, index">
                  <a-button
                    type="link"
                    style="color: red"
                    @click="del(record, index)"
                    >删除</a-button
                  >
                </template>
              </a-table>
            </a-form-model>
          </div>
          <div class="box questionList">
            <div class="header">原始题号列表</div>
            <div class="conetent">
              <div
                v-for="(item, index) in questionList"
                :key="index"
                class="questionNumItem"
                @click="handleSelect(item, index)"
              >
                {{ item.questionNum }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { savePlate, getPlate, delPlate } from "@/core/api/exam/twoWay";
export default {
  props: {
    examPaperId: {
      type: [String, Number, null],
      default: null,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
    specificationId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      loading: false,
      columns: [
        {
          title: "排序",
          dataIndex: "sort",
          align: "center",
          scopedSlots: { customRender: "sort" },
          width: "75px",
        },
        {
          title: "版块",
          dataIndex: "name",
          align: "center",
          scopedSlots: { customRender: "name" },
          width: "110px",
        },
        {
          title: "题号",
          dataIndex: "qustionNum",
          align: "center",
          scopedSlots: { customRender: "questionNum" },
        },
        {
          title: "操作",
          dataIndex: "actions",
          align: "center",
          width: "75px",
          scopedSlots: { customRender: "actions" },
        },
      ],
      form: {
        tableData: [{ sort: 1 }],
      },
      rules: {},
      currentRow: {},
      questionList: [],
      hasSamePlateName: false,
    };
  },
  mounted() {},
  methods: {
    async getPlateData() {
      try {
        this.loading = true;
        const res = await getPlate({ examPaperId: this.examPaperId });
        let questionNums = res.data.data.map((item) => item.questionNum);
        this.questionList = this.questionList.filter(
          (item) => !questionNums.includes(item.questionNum)
        );
        let questionNumPlates = [
          ...new Set(res.data.data.map((item) => item.questionNumPlate)),
        ];
        this.form.tableData = questionNumPlates.flatMap((item) => {
          let arr = res.data.data.filter((it) => it.questionNumPlate === item);
          let obj = res.data.data.find((it) => it.questionNumPlate == item);
          return {
            name: item,
            ids: arr.map((item) => item.id).join(","),
            questionNum: arr,
            sort: obj.sort,
          };
        });
        if (this.form.tableData.length === 0) {
          this.add();
        } else {
          this.$nextTick(() => {
            let trs = document
              .getElementsByClassName("ant-table-tbody")[1]
              .querySelectorAll("tr");
            if (trs.length && this.form.tableData.length) {
              trs[0].style.background = "#fff5d7";
              this.currentRow = {
                record: this.form.tableData[0],
                index: 0,
              };
            }
          });
        }

        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    customRow(record, index) {
      return {
        on: {
          // 鼠标单击行
          click: (event) => {
            this.currentRow = {
              record: record,
              index: index,
            };
            event.currentTarget.parentNode
              .querySelectorAll("tr")
              .forEach((item) => {
                item.style.background = "white";
              });
            event.currentTarget.style.background = "#fff5d7";
          },
        },
      };
    },
    delItem(item, index, ind) {
      this.questionList.push(item);
      this.questionList.sort((a, b) => a - b);
      this.form.tableData[index].questionNum.splice(ind, 1);
    },
    handleSelect(item, ind) {
      const { index } = this.currentRow;
      if (index !== null && index !== undefined) {
        this.form.tableData[index].questionNum.push(item);
        this.questionList.splice(ind, 1);
      } else {
        this.$message({
          type: "warning",
          showClose: true,
          message: "请选择一个版块",
        });
      }
    },
    openModal() {
      this.visible = true;

      this.questionList = [...this.dataSource];

      this.getPlateData();
    },
    add() {
      let sortList = this.form.tableData.length
        ? this.form.tableData.map((item) => item.sort)
        : [];
      let maxSort = sortList.length ? Math.max(...sortList) : 0;
      this.form.tableData.push({
        questionNum: [],
        name: "",
        sort: maxSort + 1,
      });
      if (this.form.tableData.length === 1) {
        this.$nextTick(() => {
          let trs = document
            .getElementsByClassName("ant-table-tbody")[1]
            .querySelectorAll("tr");
          if (trs.length && this.form.tableData.length) {
            trs[0].style.background = "#fff5d7";
            this.currentRow = {
              record: this.form.tableData[0],
              index: 0,
            };
          }
        });
      }
    },
    del(record, index) {
      this.$confirm("确定删除当前版块", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          if (record.questionNum.length) {
            this.questionList = [
              ...this.questionList,
              ...record.questionNum,
            ].sort((a, b) => a - b);
          }
          if (record.ids) {
            this.form.tableData.splice(index, 1);
            await delPlate({ ids: record.ids });
            this.$emit("change");
          } else {
            this.form.tableData.splice(index, 1);
          }
          if (index === this.currentRow.index) {
            this.currentRow = {};
          }
          this.$message({
            type: "success",
            message: "删除成功",
            showClose: true,
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleOk() {
      // this.visible = false;
      if (!this.form.tableData.length) {
        this.$message({
          type: "error",
          message: "请先添加一个版块",
          showClose: true,
        });
        return false;
      }
      if (this.hasSamePlateName) {
        this.$message({
          type: "error",
          message: "版块名称不能重复",
          showClose: true,
        });
        return false;
      }
      let data = this.form.tableData.map((item) => {
        return {
          examPaperId: this.examPaperId,
          specificationId: this.specificationId,
          sort: item.sort,
          sheetDetailIds: item.questionNum
            .map((it) => it.sheetDetailId)
            .join(","),
          questionNumPlate: item.name,
        };
      });
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.confirmLoading = true;
            await savePlate(data);
            this.confirmLoading = false;
            this.visible = false;
            this.$message({
              showClose: true,
              message: "保存成功",
              type: "success",
              duration: 1000,
            });
            this.$emit("change");
          } catch {
            this.confirmLoading = false;
          }
        }
      });
    },
    validSort(rule, value, callback, record, index) {
      let data = this.form.tableData[index].sort;
      if (/^\d+$/.test(data) && Number(data) > 0) {
        return callback();
      } else {
        return callback(new Error(" "));
      }
    },
    validPlateName(rule, value, callback, record, index) {
      let data = this.form.tableData[index].name;
      if (!data) {
        return callback(new Error(" "));
      } else {
        let result = this.form.tableData.filter((item) => item.name == data);
        if (result.length > 1) {
          this.hasSamePlateName = true;
          return callback(new Error(" "));
        } else {
          this.hasSamePlateName = false;
        }
        return callback();
      }
    },
    validateQuestionNum(rule, value, callback, record, index) {
      let questionNumBox = document.getElementsByClassName("questionNumBox");
      if (!record.questionNum.length) {
        questionNumBox[index].style.borderColor = "#f5222d";

        callback(new Error("  "));
      } else {
        questionNumBox[index].style.borderColor = "transparent";
        callback();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin baseFlex($ai: center, $jc: center) {
  display: flex;
  align-items: $ai;
  justify-content: $jc;
}

.plateDivision {
  margin-right: 8px;
}

.tips {
  background: #f3f8ff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 19px;
  color: #262829;
}

.subject {
  // margin: 16px 0;
  margin-bottom: 16px;
  color: #0a1119;
  font-size: 14px;
  font-weight: 600;
}

.operate-box {
  @include baseFlex(flex-start, space-between);

  .box {
    border: 1px solid #dee0e7;
  }

  .box + .box {
    margin-left: 16px;
  }

  .table {
    width: 472px;
  }

  .questionList {
    height: 339px;
    width: 268px;
    overflow-y: scroll;

    .header {
      border-radius: 4px 4px 0 0;
      height: 44px;
      background: #f5f7fa;
      border-bottom: 1px solid #dee0e7;
      box-sizing: border-box;
      padding: 0 18px;
      color: #0a1119;
      font-size: 14px;
      font-weight: 600;
      line-height: 44px;
    }

    .conetent {
      box-sizing: border-box;
      padding: 18px;
      @include baseFlex(flex-start, flex-start);
      flex-wrap: wrap;

      .questionNumItem {
        min-width: 32px;
        height: 32px;
        margin-right: 8px;
        margin-bottom: 8px;
        color: #2474ed;
        background: #f2f6ff;
        cursor: pointer;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}

.questionNumBox {
  @include baseFlex(flex-start, flex-start);
  // border: 1px solid #d9d9d9;
  border: 1px solid transparent;
  width: 100%;
  min-height: 32px;
  border-radius: 4px;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 4px 11px;
  transition: all 0.3s;
  flex-wrap: wrap;
  grid-gap: 4px;
  span {
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.35);
  }
  .quesitonItem {
    min-width: 32px;
    height: 24px;
    padding: 0 4px;
    text-align: center;
    color: #2474ed;
    background: #f2f6ff;
    border-radius: 4px;
    cursor: pointer;
    line-height: 24px;
    font-size: 12px;
    user-select: none;
  }
}

::v-deep .ant-form-item {
  margin-bottom: 0 !important;
}

::v-deep .ant-form-explain {
  display: none !important;
}

::v-deep .ant-table-row td {
  padding: 4px !important;
}

::v-deep .ant-table-placeholder {
  height: 295px;
}
.nameInput {
  border-color: #fff;
}
.has-error {
  input {
    border-color: red !important;
  }
}
</style>
