<template>
  <div class="settingReason">
    <a-button @click="handleClick">批量设置失分原因</a-button>
    <a-modal
      v-model="visible"
      title="批量设置失分原因"
      :confirm-loading="confirmLoading"
      width="45%"
      @ok="handleOk"
    >
      <a-table
        :columns="columns"
        :data-source="tableData"
        bordered
        :row-key="(record, index) => index"
        :pagination="false"
      >
        <template slot="reasonForLossOfPoints" slot-scope="text, record, index">
          <reasonVue
            class="reasonVue"
            :source-data="analysisData"
            :reason-for-loss-of-points.sync="record.reasonForLossOfPoints"
            :other-reason.sync="record.otherReason"
            :has-other="hasOther"
            @change="(value) => reasonChange(value, record, index)"
          />
        </template>
      </a-table>
    </a-modal>
  </div>
</template>
<script>
import { getPlate, saveReason } from "@/core/api/exam/twoWay";
import reasonVue from "./reason.vue";
export default {
  name: "SettingReason",
  components: {
    reasonVue,
  },
  props: {
    examPaperId: {
      type: [String, Number, null],
      default: null,
    },
    analysisData: {
      type: Array,
      default: () => [],
    },
    hasOther: {
      type: Boolean,
      default: false,
    },
    formTableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      confirmLoading: false,
      tableData: [],
      columns: [
        {
          title: "版块名称",
          dataIndex: "name",
          width: 120,
          align: "center",
        },
        {
          title: "失分原因分析",
          dataIndex: "reasonForLossOfPoints",
          align: "center",
          scopedSlots: { customRender: "reasonForLossOfPoints" },
        },
      ],
    };
  },
  created() {},
  methods: {
    reasonChange(data, record) {
      this.$set(record, "reasonForLossOfPoints", data.type);
      if (data.text) {
        this.$set(record, "otherReason", data.text);
      }
    },
    async handleOk() {
      this.confirmLoading = true;
      let hasPalteData = this.formTableData.filter(
        (item, index) =>
          item.plateName && index !== this.formTableData.length - 1
      );
      let priomiseList = hasPalteData.map((item) => {
        let obj = this.tableData.find((it) => it.name == item.plateName);
        let data = {
          id: item.id,
          reasonForLossOfPoints:
            obj != undefined
              ? obj.reasonForLossOfPoints
              : item.reasonForLossOfPoints,
          otherReason: obj != undefined ? obj.otherReason : item.otherReason,
          applyType: this.$parent.subIndex,
          examPaperId: this.examPaperId,
          classNum: this.$parent.subIndex == 1 ? this.$parent.classNum : null,
          sheetDetailId: item.sheetDetailId,
        };
        return saveReason(data);
      });
      try {
        await Promise.all(priomiseList);
        this.confirmLoading = false;
        this.visible = false;
        this.$message({
          type: "success",
          message: "保存成功",
          showClose: false,
        });
        this.$emit("update");
      } catch {
        this.confirmLoading = false;
      }
    },
    handleClick() {
      this.confirmLoading = true;
      this.visible = true;
      this.getData();
    },
    async getData() {
      const res = await getPlate({ examPaperId: this.examPaperId });
      this.confirmLoading = false;
      let questionNumPlates = [
        ...new Set(res.data.data.map((item) => item.questionNumPlate)),
      ];
      this.tableData = questionNumPlates.flatMap((item) => {
        let arr = res.data.data.filter((it) => it.questionNumPlate === item);
        let single = res.data.data.find((it) => it.questionNumPlate === item);
        return {
          name: item,
          ids: arr.map((i) => i.id).join(","),
          questionNum: arr,
          otherReason: single.otherReason,
          reasonForLossOfPoints: single.reasonForLossOfPoints,
        };
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.settingReason {
}
</style>
